import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import { upload } from "api/admin/file";
import "react-quill/dist/quill.snow.css";
import "styles/admin/editor.css";

export default function TextEditor({ value, setValue }) {
  const quillRef = useRef(null);

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener("change", async () => {
      const file = input.files[0];
      if (file.size > 10000000) {
        return alert("10mb 이하의 파일을 업로드 해주세요.");
      }
      const uploadForm = new FormData();
      uploadForm.append("file", file);
      try {
        const res = await upload(uploadForm);
        const imgUrl = res.data.url;
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, "image", imgUrl);
        editor.setSelection(range.index + 1);
      } catch (err) {
        console.log(err);
      }
    });
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ["image", "video"],
        ],
        handlers: { image: imageHandler },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "align",
    "image",
    "video",
  ];

  return (
    <div className="h-[32rem] grow">
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        theme="snow"
      />
    </div>
  );
}
