import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

export default function Intro() {
  const [title, setTitle] = useState();
  const { pathname } = useLocation();
  useEffect(() => {
    const path = pathname.slice(7)
    if (path === "yacht") {
      setTitle("1004 요트투어 소개");
    } else if (path === "product") {
      setTitle("상품소개");
    } else if (path === "clubhouse") {
      setTitle("요트클럽하우스");
    } else {
      setTitle("보도자료");
    }
  }, [pathname])
  
  return (
    <main className="main-pages">
      <header id="intro-header">
        <picture>
          <source media="(min-width: 1024px)" srcSet={require("assets/images/header/intro_desktop.webp")}/>
          <source media="(min-width: 768px)" srcSet={require("assets/images/header/intro_tablet.webp")}/>
          <img src={require("assets/images/header/intro_mobile.webp")} alt="intro" />
        </picture>
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><Link to="/intro">소개</Link></li>
          <li><NavLink to={pathname}>{title}</NavLink></li>
        </ul>
      </header>
      <h1 className="title">{title}</h1>
      <Outlet />
    </main>
  );
}
