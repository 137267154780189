import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// client
import Header from "components/client/Header";
import Footer from "components/client/Footer";
import Aside from "components/client/Aside";
import Home from "pages/client/home";
import Reservation from "pages/client/reservation";
import Intro from "pages/client/intro";
import Yacht from "pages/client/intro/yacht";
import Product from "pages/client/intro/product";
import ClubHouse from "pages/client/intro/clubhouse";
import Press from "pages/client/intro/press";
import Gallery from "pages/client/gallery";
import GalleryList from "pages/client/gallery/list";
import GalleryDetail from "pages/client/gallery/detail";
import Event from "pages/client/event";
import EventList from "pages/client/event/list";
import EventDetail from "pages/client/event/detail";
import Support from "pages/client/support";
import Notice from "pages/client/support/notice";
import NoticeList from "pages/client/support/notice/list";
import NoticeDetail from "pages/client/support/notice/detail";
import Qna from "pages/client/support/qna";
import QnaList from "pages/client/support/qna/list";
import QnaDetail from "pages/client/support/qna/detail";
import QnaCreate from "pages/client/support/qna/create";
import Policy from "pages/client/policy";
import Privacy from "pages/client/policy/privacy";
import Use from "pages/client/policy/use";
import Operation from "pages/client/policy/operation";
import NotFound from "pages/client/404";

// admin
import Admin from "pages/admin";
import Login from "pages/admin/login";
import AdminArticle from "pages/admin/article";
import AdminMain from "pages/admin/main";
import AdminCreateMain from "pages/admin/main/create";
import AdminUpdateMain from "pages/admin/main/update";
import AdminClubHouse from "pages/admin/article/clubhouse";
import AdminCreateClubHouse from "pages/admin/article/clubhouse/create";
import AdminUpdateClubHouse from "pages/admin/article/clubhouse/update";
import AdminPress from "pages/admin/article/press";
import AdminCreatePress from "pages/admin/article/press/create";
import AdminUpdatePress from "pages/admin/article/press/update";
import AdminNotice from "pages/admin/article/notice";
import AdminCreateNotice from "pages/admin/article/notice/create";
import AdminUpdateNotice from "pages/admin/article/notice/update";
import AdminGallery from "pages/admin/article/gallery";
import AdminCreateGallery from "pages/admin/article/gallery/create";
import AdminUpdateGallery from "pages/admin/article/gallery/update";
import AdminEvent from "pages/admin/article/event";
import AdminCreateEvent from "pages/admin/article/event/create";
import AdminUpdateEvent from "pages/admin/article/event/update";
import AdminSupport from "pages/admin/supoort";
import AdminUpdateSupport from "pages/admin/supoort/update";
import "styles/client/app.sass";

function App() {
  const { pathname } = useLocation();

  function setScreenSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
    window.addEventListener("resize", setScreenSize);
    return () => {
      window.removeEventListener("resize", setScreenSize);
    };
  }, []);

  return (
    <>
      {!pathname.startsWith("/admin") && <Header />}
      {!pathname.startsWith("/admin") && <Aside />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/intro" element={<Intro />}>
          <Route index element={<Navigate to="yacht" />} />
          <Route path="yacht" element={<Yacht />} />
          <Route path="product" element={<Product />} />
          <Route path="clubhouse" element={<ClubHouse />} />
          <Route path="press" element={<Press />} />
        </Route>
        <Route path="reservation" element={<Reservation />} />
        <Route path="/gallery" element={<Gallery />}>
          <Route path="" element={<GalleryList />} />
          <Route path=":id" element={<GalleryDetail />} />
        </Route>
        <Route path="/event" element={<Event />}>
          <Route path="" element={<EventList />} />
          <Route path=":id" element={<EventDetail />} />
        </Route>
        <Route path="/support" element={<Support />}>
          <Route index element={<Navigate to="notice?page=1" />} />
          <Route path="notice" element={<Notice />}>
            <Route path="" element={<NoticeList />} />
            <Route path=":id" element={<NoticeDetail />} />
          </Route>
          <Route path="qna" element={<Qna />}>
            <Route path="" element={<QnaList />} />
            <Route path="create" element={<QnaCreate />} />
            <Route path=":id" element={<QnaDetail />} />
          </Route>
        </Route>
        <Route path="/policy" element={<Policy />}>
          <Route path="privacy" element={<Privacy />} />
          <Route path="use" element={<Use />} />
          <Route path="operation" element={<Operation />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/admin" element={<Admin />}>
          <Route index element={<Navigate to="main?page=1" />} />
          <Route path="login" element={<Login />} />
          <Route path="main" element={<AdminMain />} />
          <Route path="main/create" element={<AdminCreateMain />} />
          <Route path="main/:id" element={<AdminUpdateMain />} />
          <Route path="article" element={<AdminArticle />}>
            <Route index element={<Navigate to="clubhouse" />} />
            <Route path="clubhouse" element={<AdminClubHouse />} />
            <Route path="clubhouse/create" element={<AdminCreateClubHouse />} />
            <Route path="clubhouse/:id" element={<AdminUpdateClubHouse />} />

            <Route path="press" element={<AdminPress />} />
            <Route path="press/create" element={<AdminCreatePress />} />
            <Route path="press/:id" element={<AdminUpdatePress />} />

            <Route path="notice" element={<AdminNotice />} />
            <Route path="notice/create" element={<AdminCreateNotice />} />
            <Route path="notice/:id" element={<AdminUpdateNotice />} />

            <Route path="gallery" element={<AdminGallery />} />
            <Route path="gallery/create" element={<AdminCreateGallery />} />
            <Route path="gallery/:id" element={<AdminUpdateGallery />} />

            <Route path="event" element={<AdminEvent />} />
            <Route path="event/create" element={<AdminCreateEvent />} />
            <Route path="event/:id" element={<AdminUpdateEvent />} />
          </Route>
          <Route path="support" element={<AdminSupport />} />
          <Route path="support/:id" element={<AdminUpdateSupport />} />
        </Route>
      </Routes>
      {!pathname.startsWith("/admin") && <Footer />}
    </>
  );
}

export default App;
