import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

export default function Gallery() {
  return (
    <main className="main-pages">
      <header id="gallery-header">
        <picture>
          <source media="(min-width: 1024px)" srcSet={require("assets/images/header/gallery_desktop.webp")}/>
          <source media="(min-width: 768px)" srcSet={require("assets/images/header/gallery_tablet.webp")}/>
          <img src={require("assets/images/header/gallery_mobile.webp")} alt="gallery" />
        </picture>
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><NavLink to="/gallery">갤러리</NavLink></li>
        </ul>
      </header>
      <h1 className="title">갤러리</h1>
      <Outlet />
    </main>
  );
}
