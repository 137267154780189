import React, { useEffect, useState } from "react";
import { getCoffee, getFacility } from "api/pages/intro";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";

export default function ClubHouse() {
  const [coffeeList, setCoffeeList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);

  useEffect(() => {
    async function handleGetList() {
      try {
        const coffee = await getCoffee();
        const facility = await getFacility();
        setCoffeeList(coffee.data.content);
        setFacilityList(facility.data.content);
      } catch (err) {
        console.log(err);
      }
    }
    handleGetList();
  }, []);

  return (
    <article id="clubhouse-page">
      {!coffeeList?.length && !facilityList?.length ? (
        <section className="prepare-section">
          <h2>오픈 준비중</h2>
          <p>
            요트클럽하우스를 찾아주시는 모든 분들께
            <br />
            더 나은 서비스를 제공하기 위해 준비 중에 있습니다.
            <br />곧 찾아 뵙겠습니다. 감사합니다.
          </p>
        </section>
      ) : (
        <>
          <section className="coffee-section">
            <h3>커피 및 제과</h3>
            <div className="carousel-container">
              <div className="carousel-wrap">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1}
                  fadeEffect={{ crossFade: true }}
                  navigation
                >
                  {coffeeList?.map((item) => (
                    <SwiperSlide key={item.id}>
                      <img
                        src={item.thumbnailFile?.url}
                        alt={item.thumbnailFile?.name}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
          <section className="facility-section">
            <h3>시설</h3>
            <div className="carousel-container">
              <div className="carousel-wrap">
                <Swiper
                  modules={[Navigation]}
                  slidesPerView={1}
                  fadeEffect={{ crossFade: true }}
                  navigation
                >
                  {facilityList?.map((item) => (
                    <SwiperSlide key={item.id}>
                      <img
                        src={item.thumbnailFile?.url}
                        alt={item.thumbnailFile?.name}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section>
        </>
      )}
    </article>
  );
}
