import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DesktopHeader from "components/client/home/DesktopHeader";
import TabletHeader from "components/client/home/TabletHeader";
import MobileHeader from "components/client/home/MobileHeader";
import DesktopSwiper from "components/client/home/DesktopSwiper";
import TabletSwiper from "components/client/home/TabletSwiper";
import MobileSwiper from "components/client/home/MobileSwiper";
import { getDateYMD } from "utils";
import { getBanner, getNotice } from "api/pages/main";
import RightArrowLogo from "assets/images/common/arrow-right.svg";

export default function Home() {
  const [bannerList, setBannerList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);

  useEffect(() => {
    async function handleGetList() {
      try {
        const banners = await getBanner();
        const notices = await getNotice();
        setBannerList(banners.data.content);
        setNoticeList(notices.data.content);
      } catch (err) {
        console.log(err);
      }
    }
    handleGetList();
  }, []);

  return (
    <main className="main-home">
      <DesktopHeader bannerList={bannerList} />
      <TabletHeader bannerList={bannerList} />
      <MobileHeader bannerList={bannerList} />
      <article>
        <section className="section-carousel">
          <div className="carousel-wrap">
            <DesktopSwiper />
            <TabletSwiper />
            <MobileSwiper />
          </div>
          <div className="intro-wrap">
            <h2>1004 요트투어 소개</h2>
            <p className="desktop">
              화려한요트에서 즐기는 천사의섬 신안의 풍광.<br />
              바쁜 일상에서 벗어나 파도소리, 바람소리, 저녁노을,<br />
              아침 안개속에서 삶의 여유를 찾아보세요.
            </p>
            <p className="tablet">
              화려한요트에서 즐기는 천사의섬 신안의 풍광. 바쁜 일상에서 벗어나 파도소리, <br />
              바람소리, 저녁노을, 아침 안개속에서 삶의 여유를 찾아보세요.
            </p>
            <p className="mobile">
              화려한요트에서 즐기는 천사의섬 신안의 풍광.<br />
              바쁜 일상에서 벗어나 파도소리, 바람소리, 저녁노을,<br />
              아침 안개속에서 삶의 여유를 찾아보세요.
            </p>
            <Link to="intro/yacht">자세히보기</Link>
          </div>
        </section>
        <section className="section-product">
          <div className="text-wrap">
            <h2>상품소개</h2>
            <p>1004요트투어 상품을 자세히 볼 수 있습니다.</p>
            <Link to="intro/product">자세히보기</Link>
          </div>
          <div className="img-wrap">
            <div>
              <img src={require("assets/images/pages/home/general.webp")} alt="일반 투어" loading="lazy" />
              <h5>일반 투어</h5>
            </div>
            <div>
              <img src={require("assets/images/pages/home/sunset.webp")} alt="일몰 투어" loading="lazy" />
              <h5>일몰 투어</h5>
            </div>
          </div>
        </section>
        <section className="section-reservation">
          <picture>
            <source media="(min-width: 1024px)" srcSet={require("assets/images/pages/home/reservation_desktop.webp")} />
            <source media="(min-width: 768px)" srcSet={require("assets/images/pages/home/reservation_tablet.webp")} />
            <img src={require("assets/images/pages/home/reservation_mobile.webp")} alt="reservation" loading="lazy" />
          </picture>
          <div className="link-wrap">
            <h1>예약하기</h1>
            <Link to="/reservation">
              <img src={RightArrowLogo} alt="예약하기" />
            </Link>
          </div>
          <h4>소중한 사람들과 특별한 시간을 원한다면?</h4>
        </section>
        <section className="section-link">
          <div className="link-container">
            <div className="text-wrap">
              <a href="https://www.instagram.com/1004yacht/" target="_blank" rel="noreferrer">1004요트관광 인스타그램 &gt;</a>
              <p>
                인스타그램에서 즐거운 요트관광<br />
                사진을 만나보실 수 있습니다.
              </p>
            </div>
            <img src={require("assets/images/pages/home/instagram.webp")} alt="instagram" loading="lazy" />
          </div>
          <div className="link-container">
            <div className="text-wrap">
              <a href="https://www.youtube.com/channel/UCxBgjA6Wy-k0QwcR__4CDmA" target="_blank" rel="noreferrer">1004요트관광 유튜브 &gt;</a>
              <p>
                유튜브에서 멋있는 요트관광<br />
                영상을 만나보실 수 있습니다.
              </p>
            </div>
            <img src={require("assets/images/pages/home/youtube.webp")} alt="instagram" loading="lazy" />
          </div>
        </section>
        <section className="section-support">
          <div className="support-wrap">
            <div>
              <h2>공지사항</h2>
              <Link to="support/notice?page=1">더보기 &gt;</Link>
            </div>
            <ul>
              {noticeList?.map((item) => (
                <li key={item.id}>
                  <Link to={`support/notice/${item.id}`}>
                    <p>{item.title}</p>
                    <span>{getDateYMD(item.createdAt)}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="map-wrap">
            <div className="header">
              <h2>위치 안내</h2>
              <Link to="intro/yacht#address">자세히 보기 &gt;</Link>
            </div>
            <div className="content-wrap">
              <picture className="map-img">
                <source media="(min-width: 1024px)" srcSet={require("assets/images/pages/home/map_desktop.webp")} />
                <source media="(min-width: 768px)" srcSet={require("assets/images/pages/home/map_tablet.webp")} />
                <img src={require("assets/images/pages/home/map_mobile.webp")} alt="위치 안내" loading="lazy" />
              </picture>
              <div className="text-wrap">
                <p>
                  <b>오도선착장</b><br />
                  전라남도 신안군 암태면 신석리 1-11
                </p>
                <p className="phone-wrap">
                  010-9629-1880
                </p>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}
