import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { navOpenState } from "store";
import Hamburger from "assets/images/common/hamburger.svg";
import LogoWhite from "assets/images/common/logo-white.svg";

export default function Header() {
  const [navOpen, setNavOpen] = useRecoilState(navOpenState);
  const [showIntroMenu, setShowIntroMenu] = useState(false);
  const [showSupportMenu, setShowSupportMenu] = useState(false);

  return (
    <header>
      <div className="header-wrap">
        <div className="home-wrap">
          <Link to="/">
            <img src={LogoWhite} alt="yacht1004" />
          </Link>
        </div>
        <nav>
          <ol>
            <li
              onMouseOver={() => setShowIntroMenu(true)}
              onMouseLeave={() => setShowIntroMenu(false)}
            >
              <NavLink to="intro">소개</NavLink>
              {showIntroMenu && (
                <ol className="dropdown-menu">
                  <li><NavLink to="intro/yacht">1004 요트투어</NavLink></li>
                  <li><NavLink to="intro/product">상품소개</NavLink></li>
                  <li><NavLink to="intro/clubhouse">요트클럽하우스</NavLink></li>
                  <li><NavLink to="intro/press">보도자료</NavLink></li>
                </ol>
              )}
            </li>
            <li><NavLink to="gallery">갤러리</NavLink></li>
            <li><NavLink to="event">이벤트</NavLink></li>
            <li
              onMouseOver={() => setShowSupportMenu(true)}
              onMouseLeave={() => setShowSupportMenu(false)}
            >
              <NavLink to="support">고객센터</NavLink>
              {showSupportMenu && (
                <ol className="dropdown-menu">
                  <li><NavLink to="support/notice">공지사항</NavLink></li>
                  <li><NavLink to="support/qna">Q&A</NavLink></li>
                </ol>
              )}
            </li>
          </ol>
        </nav>
        <button className="menu-btn mobile">
          <img src={Hamburger} alt="menu" onClick={() => setNavOpen(!navOpen)}/>
        </button>
      </div>
    </header>
  );
}
