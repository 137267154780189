import React from "react";
import { Link } from "react-router-dom";
import LogoFooter from "assets/images/common/logo-footer.svg";
import Instagram from "assets/images/common/instagram.svg";
import Youtube from "assets/images/common/youtube.svg";
// import Facebook from "assets/images/common/facebook.svg";
// import Kakao from "assets/images/common/kakao.svg";
// import Naver from "assets/images/common/naver.svg";

export default function Footer() {
  return (
    <footer>
      <div className="footer-wrap">
        <div className="image-wrap">
          <img src={LogoFooter} alt="yacht1004" />
        </div>
        <div className="content-wrap">
          <div className="policy-wrap">
            <div className="link-wrap">
              <Link to="/policy/privacy">개인정보처리방침</Link>
              <Link to="/policy/use">이용약관</Link>
              <Link to="/policy/operation">운행약관</Link>
            </div>
            <div className="address-wrap">
              <div className="desktop">
                <p><span>1004섬 요트관광 주식회사</span><span>사업자 번호 888-81-01451</span><span>대표이사 : 임규복</span></p>
                <p><span>주소 : 전남 신안군 암태면 박달로 9, 암태오도여객선터미널(오도선착장)</span><span>고객센터 : 061-271-7774 ( 010-9629-1880 )</span><span>이메일 : pusanyat@hanmail.net</span></p>
              </div>
              <div className="tablet">
                <p><span>1004섬 요트관광 주식회사</span><span>사업자 번호 888-81-01451</span><span>대표이사 : 임규복</span></p>
                <p><span>주소 : 전남 신안군 암태면 박달로 9, 암태오도여객선터미널(오도선착장)</span></p>
                <p><span>고객센터 : 061-271-7774 ( 010-9629-1880 )</span><span>이메일 : pusanyat@hanmail.net</span></p>
              </div>
              <div className="mobile">
                <p>1004섬 요트관광 주식회사</p>
                <p>사업자 번호 888-81-01451</p>
                <p>대표이사 : 임규복</p>
                <p>주소 : 전남 신안군 암태면 박달로 9, 암태오도여객선터미널(오도선착장)</p>
                <p>고객센터 : 061-271-7774 ( 010-9629-1880 )</p>
                <p>이메일 : pusanyat@hanmail.net</p>
              </div>
            </div>
            <p className="copyright-wrap desktop">
              Copyright 1004요트투어. ALL RIGHTS RESERVED.
            </p>
            <p className="copyright-wrap tablet">
              Copyright 1004요트투어. ALL RIGHTS RESERVED.
            </p>
            <p className="copyright-wrap mobile">
              Copyright 1004요트투어. ALL RIGHTS<br />RESERVED.
            </p>
          </div>
          <div className="sns-wrap">
            <a href="https://www.instagram.com/1004yacht/" target="_blank" rel="noreferrer"><img src={Instagram} alt="instagram" /></a>
            <a href="https://www.youtube.com/channel/UCxBgjA6Wy-k0QwcR__4CDmA" target="_blank" rel="noreferrer"><img src={Youtube} alt="youtube" /></a>
            {/* <a href="/"><img src={Facebook} alt="facebook" /></a>
            <a href="/"><img src={Kakao} alt="kakao" /></a>
            <a href="/"><img src={Naver} alt="naver" /></a> */}
          </div>
        </div>
      </div>
    </footer>
  );
}