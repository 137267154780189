import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { navOpenState } from "store";
import Close from "assets/images/common/close.svg"
import ChevronDown from "assets/images/common/chevron-down.svg"

export default function Aside() {
  const location = useLocation();
  const [navOpen, setNavOpen] = useRecoilState(navOpenState);
  const [showIntroMenu, setShowIntroMenu] = useState(false);
  const [showSupportMenu, setShowSupportMenu] = useState(false);

  function changeIntroMenu(e) {
    e.preventDefault();
    setShowIntroMenu(!showIntroMenu);
    setShowSupportMenu(false);
  }
  
  function changeSupportMenu(e) {
    e.preventDefault();
    setShowSupportMenu(!showSupportMenu);
    setShowIntroMenu(false);
  }

  useEffect(() => {
    setNavOpen(false);
  }, [location]);
  
  return (
    <>
      {navOpen ? (
        <aside className="mobile" id="mobile-menu">
          <div className="button-wrap">
            <button onClick={() => setNavOpen(!navOpen)}>
              <img src={Close} alt="close" />
            </button>
          </div>
          <nav>
            <ul>
              <li>
                <div className="menu">
                  <NavLink to="/intro" onClick={changeIntroMenu}>소개</NavLink>
                  <button onClick={changeIntroMenu} className={showIntroMenu ? "active" : ""}>
                    <img src={ChevronDown} alt="menu" />
                  </button>
                </div>
                <ul className="sub-menu" style={{height: showIntroMenu ? "50vw" : "0"}}>
                  <li><NavLink to="/intro/yacht">1004 요트투어 소개</NavLink></li>
                  <li><NavLink to="/intro/product">상품 소개</NavLink></li>
                  <li><NavLink to="/intro/clubhouse">요트클럽하우스 소개</NavLink></li>
                  <li><NavLink to="/intro/press">보도자료</NavLink></li>
                </ul>
              </li>
              <li><NavLink to="/gallery">갤러리</NavLink></li>
              <li><NavLink to="/event">이벤트</NavLink></li>
              <li>
                <div className="menu">
                  <NavLink to="/support" onClick={changeSupportMenu}>고객센터</NavLink>
                  <button onClick={changeSupportMenu} className={showSupportMenu ? "active" : ""}>
                    <img src={ChevronDown} alt="menu" />
                  </button>
                </div>
                <ul className="sub-menu" style={{height: showSupportMenu ? "30.56vw" : "0"}}>
                  <li><NavLink to="/support/notice">공지사항</NavLink></li>
                  <li><NavLink to="/support/qna">Q&A</NavLink></li>
                </ul>
              </li>
            </ul>
          </nav>
        </aside>
      ) : (
        <></>
      )}
    </>
  );
}
