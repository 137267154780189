import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getData } from "api/pages/support/notice";
import { getDateYMDT } from "utils";
import Download from "assets/images/common/download.svg"

export default function NoticeDetail() {
  const [item, setItem] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function handleGetData() {
      try {
        const res = await getData(id);
        setItem(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    handleGetData();
  }, []);

  return (
    <article id="notice-detail">
      <section className="content-section">
        <div className="title-wrap">
          <h4>{item.title}</h4>
          <p>{getDateYMDT(item.createdAt)} | 조회수 {item.view}</p>
        </div>
        <div
          className="content-wrap"
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>
        <div className="file-wrap">
          첨부파일
          {item.file &&
            <a href={item.file.url} download={item.file.name}>
              {item.file.name}
              <img src={Download} alt={item.file.name} />
            </a>}
        </div>
      </section>
      <section className="button-section">
        <Link to={-1}>목록</Link>
      </section>
    </article>
  );
}
