import { apiInstance } from "api/instace";

export default async function getNotice() {
  const res = await apiInstance.get("/main/notice", {
    params: {
      page: 0,
      size: 3,
      boardType: "NOTICE",
    },
  });
  return res;
}
