import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useRecoilValue } from "recoil";
import { adminNavOpenState } from "store";
import Aside from "components/admin/layout/Aside";
import Header from "components/admin/layout/Header";
import "styles/admin/index.css";

export default function Admin() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const cookie = new Cookies();
  const adminNavOpen = useRecoilValue(adminNavOpenState);

  function checkAdmin() {
    if (!cookie.get("admin")) {
      navigate("login");
    }
  }

  useEffect(() => {
    if (pathname !== "/admin/login") {
      checkAdmin();
      setInterval(checkAdmin, 3600000);
      return () => clearInterval(checkAdmin);
    }
  }, [pathname]);

  return (
    <div id="admin">
      {pathname !== "/admin/login" ? (
        <>
          <Header />
          <Aside />
          <main className={`${adminNavOpen && "ml-admin-aside"}`}>
            <Outlet />
          </main>
        </>
      ) : (
        <>
          <Outlet />
        </>
      )}
    </div>
  );
}
