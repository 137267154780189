import { apiInstance } from "api/instace";

export default async function getBanner() {
  const res = await apiInstance.get("/main/banner", {
    params: {
      page: 0,
      size: 5,
    }
  });
  return res;
}
