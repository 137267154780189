import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";

export default function MobileSwiper() {
  return (
    <div className="mobile">
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
      >
        <SwiperSlide>
          <img src={require("assets/images/pages/home/slide_1_mobile.webp")} alt="slide 1" loading="lazy" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
