import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DesktopSlide from "components/client/intro/DesktopSlide";
import TabletSlide from "components/client/intro/TabletSlide";
import MobileSlide from "components/client/intro/MobileSlide";

export default function Yacht() {
  const { hash } = useLocation();
  
  useEffect(() => {
    if (hash) {
      window.location.href = hash;
    }
  }, [hash]);
  
  return (
    <article id="yacht-page">
      <section className="intro-section">
        <div className="intro-wrap">
          <div className="text-wrap">
            <h5>전남 신안은 천사대교 개통, 목포 해양케이블카 등 관광 여건이 획기적으로<br />개선 되고 있습니다</h5>
            <p>
              전국적으로 가장 많은 1025개섬과 넓은 바다를 갖고있는 지역의 장점을 살려 해양관광산업을 신안군의 미래전략 산업 육성으로 요트투어 활성화 및 해양관광의 메카를 조성하고 있습니다. 전국 지자체 중 최초로 세일요트를 관광상품화한 신안군 암태·오도선착장에서 동절기 1일 5회, 하절기 7회 요트투어투어 상품을 운영하고있고, 서해안 일몰&야경투어는 최고의 코스입니다.<br />
              목포 유달산 해양케이블카와 목포대교, 압해 천사대교 등과 더불어 관내 오밀조밀한 천혜의 섬을 연륙 연계하고 섬마다 다양한 색을 입혀 가고싶고섬, 보고싶은섬으로 변신, 다양한 축제와 함께 신안군은 미래 서해안 해양관광지의 메카로 주목받는 미래를 계획하였습니다.<br />
              1004요트투어는 럭셔리 세일요트 체험으로 신안 중부권 관광에서 최고의 가성비를 자랑하는 명품 해상관광코스이며, 소중한 사람과 특별한 해양 체험을 즐겨 보시기 바랍니다.<br />
              일반 요트투어는 60분, 일몰&야경투어는 70분 체험으로 1시간 30분 간격으로 운영되고있습니다.<br />
              감사합니다.
            </p>
          </div>
          <img src={require("assets/images/pages/intro/yacht.webp")} alt="" />
        </div>
      </section>
      <section className="video-section">
        <video autoPlay controls loop muted playsinline>
          <source src={`${process.env.REACT_APP_BASE_URL}/videos/video-1.mp4`} type="video/mp4" />
        </video>
        <video autoPlay controls loop muted playsinline>
          <source src={`${process.env.REACT_APP_BASE_URL}/videos/video-2.mp4`} type="video/mp4" />
        </video>
      </section>
      <section className="swiper-section">
        <h3>럭셔리요트, 리얼세일링 체험도 가능하고 각종 편의시설까지!</h3>
        <DesktopSlide />
        <TabletSlide />
        <MobileSlide />
      </section>
      <section className="address-section" id="address">
        <h3>찾아오시는 길</h3>
        <div id="map">
          <picture>
            <source media="(min-width: 1024px)" srcSet={require("assets/images/pages/intro/map_desktop.webp")} />
            <source media="(min-width: 768px)" srcSet={require("assets/images/pages/intro/map_tablet.webp")} />
            <img src={require("assets/images/pages/intro/map_mobile.webp")} alt="찾아오시는 길" loading="lazy" />
          </picture>
          <div className="address-wrap">
            <p>
              <strong>오도선착장</strong><br />
              전라남도 신안군 암태면 신석리 1-11
            </p>
            <p className="phone">010-9629-1880</p>
          </div>
        </div>
      </section>
    </article>
  );
}
