import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import SearchForm from "components/client/uix/SearchForm";
import Pagination from "components/common/uix/Pagination";
import { getPress } from "api/pages/intro";
import { getDateYMD, getShortText } from "utils";

export default function Press() {
  const [pressList, setPressList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectPage, setSelectPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalCnt, setTotalCnt] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = 10;

  useEffect(() => {
    async function handleGetList() {
      const params = {
        page: searchParams.get("page") ? searchParams.get("page") - 1 : 0,
        size,
        keyword,
      };
      try {
        const res = await getPress(params);
        if (res.status === 200) {
          setPressList(res.data.content);
          setSelectPage(res.data.number);
          setPageCount(res.data.totalPages);
          setTotalCnt(res.data.totalElements);
        }
      } catch (err) {
        console.log(err);
      }
    }
    handleGetList();
  }, [searchParams]);

  async function handleSubmit(e) {
    e.preventDefault();
    const params = {
      page: 0,
      size,
      keyword,
    };
    try {
      const res = await getPress(params);
      if (res.status === 200) {
        setPressList(res.data.content);
        setPageCount(res.data.totalPages);
        setTotalCnt(res.data.totalElements);
        setSearchParams({ page: 1 });
        setSelectPage(0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handlePageClick(e) {
    setSearchParams({ page: e.selected + 1 });
  }

  return (
    <article id="press-page">
      <section>
        <SearchForm
          handleSubmit={handleSubmit}
          keyword={keyword}
          setKeyword={setKeyword}
        />
        {pressList?.length ? (
          <div className="press-table-wrap">
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>제목</th>
                  <th>작성일</th>
                </tr>
              </thead>
              <tbody>
                {pressList?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{totalCnt - size * selectPage - index}</td>
                    <td>
                      {item.url ? 
                        <>
                          <a className="desktop" href={item.url} target="_blank" rel="noreferrer">{getShortText(item.title, 120)}</a>
                          <a className="tablet" href={item.url} target="_blank" rel="noreferrer">{getShortText(item.title, 70)}</a>
                          <a className="mobile" href={item.url} target="_blank" rel="noreferrer">{getShortText(item.title, 20)}</a>
                        </> : 
                        <>
                          <Link className="desktop" to={`${item.id}`}>{getShortText(item.title, 120)}</Link>
                          <Link className="tablet" to={`${item.id}`}>{getShortText(item.title, 70)}</Link>
                          <Link className="mobile" to={`${item.id}`}>{getShortText(item.title, 20)}</Link>
                        </>
                      }
                    </td>
                    <td>{getDateYMD(item.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="article-pagination-wrap">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                selectPage={selectPage}
              />
            </div>
          </div>
        ) : (
          <div className="not-found">등록된 게시물이 없습니다.</div>
        )}
      </section>
    </article>
  );
}
