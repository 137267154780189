import React from "react";

export default function Use() {
  return (
    <>
      <section>
        <h4>제 1 장 총 칙</h4>
        <p>
          <strong>제 1 조 (목적)</strong>
          <br />이 이용약관(이하 '약관'이라 합니다)은 '1004섬요트관광'
          인터넷사이트 "http://1004yacht.com" (이하 '사이트'라 합니다)와 이용
          고객(이하 '회원'이라 합니다)간에 사이트가 제공하는 인터넷서비스(이하
          '서비스'라 합니다)의 가입조건 및 이용에 관한 제반 사항과 기타 필요한
          사항을 구체적으로 규정함을 목적으로 합니다.
        </p>
        <p>
          <strong>제 2 조 (용어의 정의)</strong>
          <br />
          (1) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />
          - '회원'이라 함은 이 약관에 동의하고 서비스를 이용하는 이용자를
          말합니다.
          <br />
          - '이용계약'이라 함은 이 약관을 포함하여 서비스 이용과 관련하여
          사이트와 회원 간에 체결하는 모든 계약을 말합니다.
          <br />
          - '이용자ID'라 함은 회원의 식별 및 서비스 이용을 위하여 회원의 신청에
          따라 사이트가 회원별로 부여하는 고유한 문자와 숫자의 조합을 말합니다.
          <br />
          - '비밀번호'라 함은 이용자ID로 식별되는 회원의 본인 여부를 검증하기
          위하여 회원이 설정하여 사이트에 등록한 고유의 문자와 숫자의 조합을
          말합니다.
          <br />
          - '단말기'라 함은 서비스에 접속하기 위해 회원이 이용하는 개인용
          컴퓨터, PDA, 휴대전화 등의 전산장치를 말합니다.
          <br />
          - '해지'라 함은 사이트 또는 회원이 이용계약을 해약하는 것을 말합니다.
          <br />
          (2) 이 약관에서 사용하는 용어 중 제1항에서 정하지 아니한 것은 관계
          법령 및 서비스별 안내에서 정하는 바에 따르며, 그 외에는 일반 관례에
          따릅니다.
        </p>
        <p>
          <strong>제 3 조 (이용약관의 효력 및 변경)</strong>
          <br />
          (1) 이 약관은 사이트를 통해 온라인으로 공시하고 회원의 동의와 사이트의
          승낙으로 효력을 발생하며, 합리적인 사유가 발생할 경우 사이트는 관련
          법령에 위배되지 않는 범위 안에서 개정할 수 있습니다.
          <br />
          개정된 약관은 정당한 절차에 따라 사이트를 통해 공지함으로써 효력을
          발휘합니다.
          <br />
          (2) 회원은 정기적으로 사이트를 방문하여 약관의 변경사항을 확인하여야
          합니다. 변경된 약관에 대한 정보를 알지 못해 발생하는 회원의 피해는
          사이트에서 책임지지 않습니다.
          <br />
          (3) 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할
          수 있습니다.
        </p>
        <p>
          <strong>제 4 조 (약관외 준칙)</strong>
          <br />
          사이트는 필요한 경우 서비스 내의 개별항목에 대하여 개별약관 또는
          운영원칙(이하 '서비스별 안내'라 합니다)를 정할 수 있으며, 이 약관과
          서비스별 안내의 내용이 상충되는 경우에는 서비스별 안내의 내용을
          우선하여 적용합니다.
        </p>
      </section>
      <section>
        <h4>제 2 장 이용계약 체결</h4>
        <p>
          <strong>제 5 조 (이용 계약의 성립)</strong>
          <br />
          (1) 이용계약은 이용자의 이용계약 내용에 대한 동의와 이용신청에 대하여
          사이트의 이용승낙으로 성립합니다.
          <br />
          (2) 이용계약에 대한 동의는 이용신청 당시 신청서 상의 '동의함' 버튼을
          누름으로써 의사표시를 합니다.
        </p>
        <p>
          <strong>제 6 조 (서비스 이용 신청)</strong>
          <br />
          (1) 회원으로 가입하여 서비스를 이용하고자 하는 이용자는 사이트에서
          요청하는 제반 정보(이용자ID, 비밀번호, 이름, 연락처 등)를 제공하여야
          합니다.
          <br />
          (2) 모든 회원은 반드시 회원 본인의 정보를 제공하여야만 서비스를 이용할
          수 있으며, 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의
          진정한 정보를 등록하지 않은 회원은 서비스 이용과
          <br />
          관련하여 아무런 권리를 주장할 수 없으며, 관계 법령에 따라 처벌 받을 수
          있습니다.
          <br />
          (3) 회원가입은 반드시 본인의 진정한 정보를 통하여만 가입할 수 있으며
          사이트는 회원이 등록한 정보에 대하여 확인조치를 할 수 있습니다. 회원은
          사이트의 확인조치에 대하여 적극 협력하여야 하며,
          <br />
          만일 이를 준수하지 아니할 경우 사이트는 회원이 등록한 정보가 부정한
          것으로 처리할 수 있습니다.
          <br />
          (4) 사이트는 회원에 대하여 등급별로 구분하여 이용시간, 이용회수,
          서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
        </p>
        <p>
          <strong>제 7 조 (개인정보의 보호 및 사용)</strong>
          <br />
          (1) 사이트는 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기
          위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및
          사이트의 개인정보 보호정책이 적용됩니다. 단, 사이트의 공식
          <br />
          사이트 이외의 링크된 사이트에서는 사이트의 개인정보 보호정책이
          적용되지 않습니다. 또한, 회원은 비밀번호 등이 타인에게 노출되지 않도록
          철저히 관리해야 하며 사이트는 회원의 귀책사유로 인해 노출된 정보에
          대해서 책임을 지지 않습니다.
          <br />
          (2) 사이트는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의
          개인정보를 제3자에게 제공할 수 있습니다.
          <br />
          - 수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우
          <br />
          - 회원의 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호
          업무를 위해 필요한 경우
          <br />- 기타 법률에 의해 요구되는 경우
        </p>
        <p>
          <strong>제 8 조 (이용 신청의 승낙과 제한)</strong>
          <br />
          (1) 사이트는 제5조, 제6조의 규정에 의한 이용신청에 대하여 업무 수행상
          또는 기술상 지장이 없는 경우에 원칙적으로 접수순서에 따라 서비스
          이용을 승낙합니다.
          <br />
          (2) 사이트는 아래사항에 해당하는 경우에 대해서 승낙을 보류할 수
          있습니다.
          <br />
          - 본인의 진정한 정보를 제공하지 아니한 이용신청의 경우
          <br />
          - 법령 위반 또는 사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한
          경우
          <br />
          - 부정한 용도로 본 서비스를 이용하고자 하는 경우
          <br />
          - 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
          <br />
          - 서비스와 경쟁관계에 있는 이용자가 신청하는 경우
          <br />
          - 법령 또는 약관을 위반하여 이용계약이 해지된 적이 있는 이용자가
          신청하는 경우
          <br />
          - 기타 규정한 제반 사항을 위반하며 신청하는 경우
          <br />
          (3) 사이트는 서비스 이용신청이 다음 각 호에 해당하는 경우에는 그
          신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
          <br />
          - 사이트가 설비의 여유가 없는 경우
          <br />
          - 사이트의 기술상 지장이 있는 경우
          <br />
          - 기타 사이트의 귀책사유로 이용승낙이 곤란한 경우
          <br />
          (4) 사이트는 이용신청고객이 관계 법령에서 규정하는 미성년자일 경우에
          서비스별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.
          <br />
          (5) 사이트는 회원 가입 절차 완료 이후 제2항 각 호에 따른 사유가 발견된
          경우 이용 승낙을 철회할 수 있습니다.
        </p>
        <p>
          <strong>제 9 조 (이용자ID 부여 및 변경 등)</strong>
          <br />
          (1) 사이트는 회원에 대하여 약관에 정하는 바에 따라 이용자 ID를
          부여합니다.
          <br />
          (2) 이용자ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경
          하고자 하는 경우에는 해당 ID를 해지하고 재가입해야 합니다.
          <br />
          (3) 사이트의 이용자ID는 회원 본인의 동의 하에 사이트 또는 자사이트가
          운영하는 사이트의 회원ID와 연결될 수 있습니다.
          <br />
          (4) 이용자ID는 다음 각 호에 해당하는 경우에는 회원의 요청 또는
          사이트의 직권으로 변경 또는 이용을 정지할 수 있습니다.
          <br />
          - 이용자ID가 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가
          우려되는 경우
          <br />
          - 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
          <br />
          - 사이트, 사이트의 서비스 또는 서비스 운영자 등의 명칭과 동일하거나
          오인 등의 우려가 있는 경우
          <br />
          - 기타 합리적인 사유가 있는 경우
          <br />
          (5) 이용자ID 및 비밀번호의 관리책임은 회원에게 있습니다. 이를 소홀이
          관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에
          대한 책임은 회원에게 있으며 사이트는 그에 대한
          <br />
          책임을 지지 않습니다.
          <br />
          (6) 기타회원 개인정보 관리 및 변경 등에 관한 사항은 서비스별 안내에
          정하는 바에 의합니다.
        </p>
      </section>
      <section>
        <h4>제 3 장 계약 당사자의 의무</h4>
        <p>
          <strong>제 10 조 (사이트의 의무)</strong>
          <br />
          (1) 사이트는 회원이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한
          서비스를 이용할 수 있도록 하여야 합니다.
          <br />
          (2) 사이트는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가
          생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체 없이 이를 수리
          또는 복구합니다.
          <br />
          (3) 사이트는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보
          보호정책을 공시하고 준수합니다.
          <br />
          (4) 사이트는 회원으로부터 제기되는 의견이나 불만이 정당하다고
          객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다.
          다만, 즉시 처리가 곤란한 경우는 회원에게 그 사유와 처리일정을
          <br />
          통보하여야 합니다.
        </p>
        <p>
          <strong>제 11 조 (회원의 의무)</strong>
          <br />
          (1) 회원은 회원가입 신청 또는 회원정보 변경 시 모든 사항을 사실에
          근거하여 본인의 진정한 정보로 작성하여야 하며, 허위 또는 타인의 정보를
          등록할 경우 이와 관련된 모든 권리를 주장할 수 없습니다.
          <br />
          (2) 회원은 약관에서 규정하는 사항과 기타 사이트가 정한 제반 규정,
          공지사항 등 사이트가 공지하는 사항 및 관계 법령을 준수하여야 하며,
          기타 사이트의 업무에 방해가 되는 행위, 사이트의 명예를 손상 시키는
          행위, 타인에게 피해를 주는 행위를 해서는 안됩니다.
          <br />
          (3) 회원은 주소, 연락처, 전자우편 주소 등 이용계약사항이 변경된 경우에
          해당 절차를 거쳐 이를 사이트에 즉시 알려야 합니다.
          <br />
          (4) 회원은 사이트의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수
          없으며, 그 영업활동의 결과에 대해 사이트는 책임을 지지 않습니다. 또한
          회원은 이와 같은 영업활동으로 사이트가 손해를 입은 경우, 회원은
          사이트에 대해 손해배상의무를 지며, 사이트는 해당 회원에 대해 서비스
          이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
          <br />
          (5) 회원은 사이트의 명시적 동의가 없는 한 서비스의 이용권한, 기타
          이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할
          수 없습니다.
          <br />
          (6) 회원은 사이트 및 제 3자의 지적 재산권을 포함한 제반 권리를
          침해하거나 제18조 각 호에 해당하는 행위를 해서는 안됩니다.
        </p>
      </section>
      <section>
        <h4>제 4 장 서비스의 이용</h4>
        <p>
          <strong>제 12 조 (서비스 이용 시간)</strong>
          <br />
          (1) 서비스 이용은 사이트의 업무상 또는 기술상 특별한 지장이 없는 한
          연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 사이트는 시스템
          정기점검, 증설 및 교체를 위해 사이트가 정한 날이나 시간에 서비스를
          일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시 중단은
          사이트를 통해 사전에 공지합니다.
          <br />
          (2) 사이트는 긴급한 시스템 점검, 증설 및 교체, 설비의 장애, 서비스
          이용의 폭주, 국가비상사태, 정전 등 부득이한 사유가 발생한 경우 사전
          예고 없이 일시적으로 서비스의 전부 또는 일부를 중단할 수<br />
          있습니다.
          <br />
          (3) 사이트는 서비스 개편 등 서비스 운영 상 필요한 경우 회원에게 사전
          예고 후 서비스의 전부 또는 일부의 제공을 중단할 수 있습니다.
        </p>
        <p>
          <strong>제 13 조 (회원의 게시물 등)</strong>
          <br />
          (1) 게시물이라 함은 회원이 서비스를 이용하면서 게시한 글, 사진, 각종
          파일과 링크 등을 말합니다.
          <br />
          (2) 회원이 서비스에 등록하는 게시물 등으로 인하여 본인 또는 타인에게
          손해나 기타 문제가 발생하는 경우 회원은 이에 대한 책임을 지게되며,
          사이트는 특별한 사정이 없는 한 이에 대하여 책임을 지지 않습니다.
          <br />
          (3) 사이트는 다음 각 호에 해당하는 게시물 등을 회원의 사전 동의 없이
          임시게시 중단, 수정, 삭제,이동 또는 등록 거부 등의 관련 조치를 취할수
          있습니다.
          <br />
          - 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는
          내용인 경우
          <br />
          - 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
          <br />
          - 불법복제 또는 해킹을 조장하는 내용인 경우 - 영리를 목적으로 하는
          광고일 경우
          <br />
          - 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
          <br />
          - 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          <br />
          - 사적인 정치적 판단이나 종교적 견해의 내용으로 사이트가 서비스 성격에
          부합하지 않는다고 판단하는 경우
          <br />
          - 사이트에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지
          않는 경우
          <br />
          - 기타 관계법령에 위배된다고 판단되는 경우
          <br />
          (4) 사이트는 게시물 등에 대하여 제3자로부터 명예훼손, 지적재산권 등의
          권리 침해를 이유로 게시중단 요청을 받은 경우 이를 임시로
          게시중단(전송중단)할 수 있으며, 게시중단 요청자와 게시물 등록자 간에
          소송, 합의 기타 이에 준하는 관련기관의 결정 등이 이루어져 사이트에
          접수된 경우 이에 따릅니다.
          <br />
          (5) 해당 게시물 등에 대해 임시게시 중단이 된 경우, 게시물을 등록한
          회원은 재게시(전송재개)를 사이트에 요청할 수 있으며, 게시 중단일로부터
          3개월 내에 재게시를 요청하지 아니한 경우 사이트는 이를
          <br />
          삭제할 수 있습니다.
        </p>
        <p>
          <strong>제 14 조 (게시물에 대한 저작권)</strong>
          <br />
          (1) 사이트가 작성한 게시물 또는 저작물에 대한 저작권 기타 지적재산권은
          사이트에 귀속합니다.
          <br />
          (2) 회원이 서비스 내에 게시한 게시물의 저작권은 게시한 회원에게
          귀속됩니다. 단, 사이트는 서비스의 운영, 전시, 전송, 배포, 홍보의
          목적으로 회원의 별도의 허락 없이 무상으로 저작권법에 규정하는 공정한
          관행에 합치되게 합리적인 범위 내에서 다음과 같이 회원이 등록한
          게시물을 사용할 수 있습니다.
          <br />
          - 서비스 내에서 회원 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및
          저작물성을 해치지 않는 범위 내에서의 편집 저작물 작성
          <br />
          - 미디어, 통신사 등 서비스 제휴 파트너에게 회원의 게시물 내용을 제공,
          전시 혹은 홍보하게 하는 것. 단, 이 경우 사이트는 별도의 동의 없이
          회원의 이용자ID 외에 회원의 개인정보를 제공하지 않습니다.
          <br />
          (3) 사이트는 전항 이외의 방법으로 회원의 게시물을 이용하고자 하는
          경우, 전화, 팩스, 전자우편 등의 방법을 통해 사전에 회원의 동의를
          얻어야 합니다.
          <br />
          (4) 회원이 이용계약 해지를 한 경우 본인 계정에 기록된 게시물(ex. 메일,
          블로그, 마이홈 등) 일체는 삭제됩니다. 단, 타인에 의해 보관, 담기
          등으로 재게시 되거나 복제된 게시물과 타인의 게시물과
          <br />
          결합되어 제공되는 게시물, 공용 게시판에 등록된 게시물 등은 그러하지
          않습니다.
        </p>
        <p>
          <strong>제 15 조 (정보의 제공)</strong>
          <br />
          (1) 사이트는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에
          대해서 전자우편이나 서신, 우편, SMS, 전화 등의 방법으로 회원에게
          제공할 수 있습니다.
          <br />
          (2) 사이트는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로
          회원의 동의 하에 관련 법령에 따라 추가적인 개인 정보를 수집할 수
          있습니다.
        </p>
        <p>
          <strong>제 16 조 (광고게재 및 광고주와의 거래)</strong>
          <br />
          (1) 사이트가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는
          광고게재를 통한 수익으로부터 나옵니다. 회원은 서비스 이용시 노출되는
          광고게재에 대해 동의합니다.
          <br />
          (2)사이트는 서비스상에 게재되어 있거나 서비스를 통한 광고주의
          판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과
          손해에 대해 책임을 지지 않습니다.
        </p>
      </section>
      <section>
        <h4>제 5 장 계약 해지 및 이용 제한</h4>
        <p>
          <strong>제 17 조 (계약 변경 및 해지)</strong>
          <br />
          (1) 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 서비스 내의
          [고객센터] 메뉴를 이용해 가입해지를 해야 합니다.
          <br />
          (2) 사이트는 이용계약을 해지하는 경우 사이트 개인정보보호정책에 따라
          회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하며, 사이트가
          직권으로 이용계약을 해지하고자 하는 경우에는 말소 전에
          <br />
          회원에게 소명의 기회를 부여합니다.
        </p>
        <p>
          <strong>제 18 조 (서비스 이용제한)</strong>
          <br />
          사이트는 회원이 서비스 이용내용에 있어서 본 약관 제 11조 내용을
          위반하거나, 다음 각 호에 해당하는 경우 서비스 이용 제한, 초기화,
          이용계약 해지 및 기타 해당 조치를 할 수 있습니다.
          <br />
          - 회원정보에 부정한 내용을 등록하거나 타인의 이용자ID, 비밀번호 기타
          개인정보를 도용하는 행위 또는 이용자ID를 타인과 거래하거나 제공하는
          행위
          <br />
          - 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용 또는 타인의 명예나
          프라이버시를 침해할 수 있는 내용의 정보, 문장, 도형, 음향, 동영상을
          전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
          <br />
          - 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통
          또는 불편을 주는 행위
          <br />
          - 사이트로부터 특별한 권리를 부여 받지 않고 사이트의 클라이언트
          프로그램을 변경하거나, 사이트의 서버를 해킹하거나, 웹사이트 또는
          게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
          <br />
          - 서비스를 통해 얻은 정보를 사이트의 사전 승낙 없이 서비스 이용 외의
          목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게
          제공하는 행위
          <br />
          - 사이트의 운영진, 직원 또는 관계자를 사칭하거나 고의로 서비스를
          방해하는 등 정상적인 서비스 운영에 방해가 될 경우
          <br />
          - 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우
          <br />
          - 3개월 이상 서비스를 이용한 적이 없는 경우
          <br />
          - 마이홈에 인덱스 파일없이 자료만 올려 놓고 파일 자료실 전용으로
          이용하는 경우
          <br />- 약관을 포함하여 사이트가 정한 제반 규정을 위반하거나 범죄와
          결부된다고 객관적으로 판단되는 등 제반 법령을 위반하는 행위
        </p>
      </section>
      <section>
        <h4>제 6 장 손해배상 및 기타사항</h4>
        <p>
          <strong>제 19 조 (손해배상)</strong>
          <br />
          (1) 사이트와 이용자는 서비스 이용과 관련하여 고의 또는 과실로
          상대방에게 손해를 끼친 경우에는 이를 배상하여야 한다. 단,
          <br />
          (2) 사이트는 무료로 제공하는 서비스의 이용과 관련하여
          개인정보보호정책에서 정하는 내용에 위반하지 않는 한 어떠한 손해도
          책임을 지지 않습니다.
        </p>
        <p>
          <strong>제 20 조 (면책조항)</strong>
          <br />
          (1) 사이트는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및 기타 이에
          준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스
          제공에 대한 책임이 면제됩니다.
          <br />
          (2) 사이트는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한
          사유로 발생한 손해에 대한 책임이 면제됩니다.
          <br />
          (3) 사이트는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이
          신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우
          책임을 지지 않습니다.
          <br />
          (4) 사이트는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나
          상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 자료로
          인한 손해에 대하여 책임을 지지 않습니다.
          <br />
          (5) 사이트는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도,
          정확성 등 내용에 대하여 책임을 지지 않으며, 회원 상호간 및 회원과 제
          3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없고,
          이로 인한 손해를 배상할 책임도 없습니다. (6) 사이트는 회원의 게시물을
          등록 전에 사전심사 하거나 상시적으로 게시물의 내용을 확인 또는
          검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지 아니합니다.
        </p>
        <p>
          <strong>제 21 조 (통지)</strong>
          <br />
          (1) 사이트가 회원에 대하여 통지를 하는 경우 회원이 사이트에 등록한
          전자우편 주소로 할 수 있습니다.
          <br />
          (2) 사이트는 불특정다수 회원에게 통지를 해야 할 경우 공지 게시판을
          통해 7일 이상 게시함으로써 개별 통지에 갈음할 수 있습니다.
        </p>
        <p>
          <strong>제 22 조 (재판권 및 준거법)</strong>
          <br />
          (1) 이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의
          관계법령과 상관습에 따릅니다.
          <br />
          (2) 사이트의 정액 서비스 회원 및 기타 유료 서비스 이용 회원의 경우
          당해 서비스와 관련하여서는 사이트가 별도로 정한 약관 및 정책에
          따릅니다.
          <br />
          (3) 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 대한민국
          광주지방법원 목포지원을 관할 법원으로 합니다.
        </p>
      </section>
    </>
  );
}
