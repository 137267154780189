import Search from "assets/images/common/search.svg";

export default function SearchForm({ handleSubmit, keyword, setKeyword }) {
  return (
    <div className="search-wrap">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="검색어를 입력하세요."
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <button type="submit">
          <img src={Search} alt="search" />
        </button>
      </form>
    </div>
  );
}
