import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

export default function Support() {
  const [title, setTitle] = useState();
  const [link, setLink] = useState();
  const { pathname } = useLocation();
  useEffect(() => {
    const path = pathname.slice(9)
    if (path.startsWith("notice")) {
      setLink("notice")
      setTitle("공지사항");
    } else {
      setLink("qna")
      setTitle("Q&A");
    }
  }, [pathname])

  return (
    <main className="main-pages">
      <header id="support-header">
        <picture>
          <source media="(min-width: 1024px)" srcSet={require("assets/images/header/support_desktop.webp")}/>
          <source media="(min-width: 768px)" srcSet={require("assets/images/header/support_tablet.webp")}/>
          <img src={require("assets/images/header/support_mobile.webp")} alt="support" />
        </picture>
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><Link to="/support">고객센터</Link></li>
          <li><NavLink to={link}>{title}</NavLink></li>
        </ul>
      </header>
      <h1 className="title">{title}</h1>
      <Outlet />
    </main>
  );
}
