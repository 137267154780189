import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToggleButton from "components/admin/uix/ToggleButton";
import { getData, updateData } from "api/admin/article";

export default function AdminUpdatePress() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function handleGetData() {
      try {
        const res = await getData(id);
        if (res.status === 200) {
          setTitle(res.data.title);
          setUrl(res.data.url);
          setIsOpen(res.data.isOpen);
        }
      } catch (err) {
        console.log(err);
      }
    }
    handleGetData();
  }, [id]);
  
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      boardType: "PRESS_RELEASE",
      yachtType: null,
      title,
      content: null,
      url,
      thumbnailFileId: null,
      isOpen,
    };
    try {
      const res = await updateData(id, data);
      if (res.status === 200) {
        alert("등록이 완료되었습니다.");
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <article>
      <h1>보도자료 관리</h1>
      <form className="admin-form" onSubmit={handleSubmit}>
        <div className="input-wrap">
          <label className="required" htmlFor="title">
            제목
          </label>
          <input
            className="flex-grow"
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="input-wrap">
          <label className="required" htmlFor="title">
            연결 URL
          </label>
          <input
            className="flex-grow"
            type="url"
            id="title"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="input-wrap">
          <label htmlFor={"open"}>공개여부</label>
          <ToggleButton value={isOpen} setValue={setIsOpen} id={"open"} />
        </div>
        <div className="flex justify-end gap-5 px-5">
          <button className="w-20 py-2 font-semibold text-white rounded-md bg-theme-light hover:bg-theme-dark">
            수정
          </button>
          <Link
            className="w-20 py-2 font-semibold text-center border rounded-md border-theme-light text-theme-light hover:bg-theme-light hover:text-white"
            to={-1}
          >
            뒤로
          </Link>
        </div>
      </form>
    </article>
  );
}
