import React from "react";
import { Cookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { adminNavOpenState } from "store";
import { logout } from "api/admin/auth";

export default function Header() {
  const cookie = new Cookies();
  const [adminNavOpen, setAdminNavOpen] = useRecoilState(adminNavOpenState);
  
  async function handlelogout() {
    try {
      const res = await logout();
      if (res.status === 200) {
        cookie.set("admin", false, { path: "/admin" });
      }
    } catch (err) {
      console.log(err);
    } finally {
      window.location.href = "/admin";
    }
  }

  return (
    <header
      className={`fixed top-0 flex items-center justify-between px-5 h-admin-header outline outline-1 outline-slate-300 flex-grow bg-white ${
        adminNavOpen
          ? "w-[calc(100vw-theme('spacing.admin-aside'))] left-admin-aside"
          : "w-full left-0"
      }`}
    >
      <div>
        <button onClick={() => setAdminNavOpen(!adminNavOpen)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <div>
        <button onClick={handlelogout}>로그아웃</button>
      </div>
    </header>
  );
}
