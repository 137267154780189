import ReactPaginate from "react-paginate";
import "styles/common/uix/pagination.sass";

export default function Pagination({ handlePageClick, pageCount, selectPage }) {
  return (
    <ReactPaginate
      className="pagination"
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={8}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      activeClassName="active"
      forcePage={selectPage}
    />
  );
}
