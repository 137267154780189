import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";

export default function TabletHeader({ bannerList }) {
  return (
    <header className="home-header tablet">
      <div className="swiper-wrap">
        <Swiper
          modules={[Autoplay, EffectFade, Navigation, Pagination]}
          slidesPerView={1}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          effect={"fade"}
          fadeEffect={{ crossFade: true }}
          navigation
          pagination
        >
          {bannerList?.map((item) => (
            <SwiperSlide key={item.desktopFile.id}>
              <img src={item.desktopFile.url} alt={item.desktopFile.name} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="intro-wrap">
        <h1>
          최고급 신안요트와
          <br />
          소중한 사람들과 특별한 시간을!
        </h1>
        <p>
          화려한 요트에서 즐기는 천사의 섬 신안의 풍광. 바쁜 일상에서 벗어나
          파도소리,
          <br />
          바람소리, 저녁노을, 아침 안개 속에서 삶의 여유를 찾아보세요.
        </p>
        <Link to="/reservation">예약하기</Link>
        {/* <a href="https://map.naver.com/p/search/%EC%8B%A0%EC%95%881004%EC%9A%94%ED%8A%B8/place/520556141?c=15.00,0,0,0,dh&placePath=%3Fentry%253Dbmp" target="_blank" rel="noreferrer">
          예약하기
        </a> */}
      </div>
    </header>
  );
}
