import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

export default function Event() {
  return (
    <main className="main-pages">
      <header id="event-header">
        <picture>
          <source media="(min-width: 1024px)" srcSet={require("assets/images/header/event_desktop.webp")}/>
          <source media="(min-width: 768px)" srcSet={require("assets/images/header/event_tablet.webp")}/>
          <img src={require("assets/images/header/event_mobile.webp")} alt="event" />
        </picture>
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><NavLink to="/event">이벤트</NavLink></li>
        </ul>
      </header>
      <h1 className="title">이벤트</h1>
      <Outlet />
    </main>
  );
}
