import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";

export default function DesktopSwiper() {
  return (
    <div className="desktop">
      <Swiper
        modules={[Autoplay, EffectFade, Navigation]}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        effect={"fade"}
        navigation
        fadeEffect={{ crossFade: true }}
      >
        <SwiperSlide>
          <img src={require("assets/images/pages/home/slide_1_desktop.webp")} alt="slide 1" loading="lazy" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
