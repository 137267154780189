import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getData } from "api/pages/support/qna";
import { getDateYMDT } from "utils";
import Download from "assets/images/common/download.svg"

export default function QnaDetail() {
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [passwordWrong, setPasswordWrong] = useState(false);
  const [item, setItem] = useState();
  const { id } = useParams();

  function handleChange(e) {
    e.preventDefault();
    setPassword(e.target.value);
    if (passwordErr) setPasswordErr(false);
    if (passwordWrong) setPasswordWrong(false);
  }

  async function handleGetData(e) {
    e.preventDefault();
    if (!password) {
      setPasswordErr(true);
      return;
    }
    const params = {
      password,
    };
    try {
      const res = await getData(id, params);
      if (res.status === 200) {
        setItem(res.data);
      }
    } catch (err) {
      if (err.response.data.code === "INVALID_PASSWORD") {
        setPasswordWrong(true);
        setPassword("");
      }
    }
  }

  return (
    <article id="qna-detail">
      {!item ? (
        <div className="login-section">
          <form onSubmit={handleGetData}>
            <h3 className="desktop">문의 등록 시 설정한 비밀번호를 입력해주세요.</h3>
            <h3 className="tablet">문의 등록 시 설정한 비밀번호를 입력해주세요.</h3>
            <h3 className="mobile">문의 등록 시 설정한 비밀번호를<br />입력해주세요.</h3>
            <input type="password" value={password} onChange={handleChange} placeholder="비밀번호 입력"/>
            {passwordErr && <p>비밀번호를 입력해 주세요.</p>}
            {passwordWrong && (
              <p>비밀번호를 잘못 입력했습니다. 다시 입력해 주세요.</p>
            )}
            <button type="submit">확인</button>
          </form>
        </div>
      ) : (
        <>
          <section className="content-section">
            <div className="title-wrap">
              <h4>{item.title}</h4>
              <p>
                {getDateYMDT(item.createdAt)} | 조회수 {item.view}
              </p>
            </div>
            <div
              className="content-wrap"
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></div>
            <div className="file-wrap">
              첨부파일
              {item.file && (
                <a href={item.file.url} download={item.file.name}>
                  {item.file.name}
                  <img src={Download} alt={item.file.name} />
                </a>
              )}
            </div>
          </section>
          {item.isAnswered && (
            <section className="answer-section">
              <div className="title-wrap">
                <h4>문의하신 내용에 대한 답변입니다.</h4>
                <p>{getDateYMDT(item.answeredAt)}</p>
              </div>
              <div
                className="content-wrap"
                dangerouslySetInnerHTML={{ __html: item.answer }}
              ></div>
            </section>
          )}
          <div className="button-section">
            <Link to={-1}>목록</Link>
          </div>
        </>
      )}
    </article>
  );
}
