import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadFile from "components/client/uix/UploadFile";
import { createData } from "api/pages/support/qna";

export default function QnaDetail() {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState();
  const [password, setPassword] = useState("");
  
  const [nameErr, setNameErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [titleLenErr, setTitleLenErr] = useState(false);
  const [contentErr, setContentErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  
  const navigate = useNavigate();
  
  function validate() {
    let err = false;
    if (name === '') { setNameErr(true); err = true; }
    else setNameErr(false);
    if (title === '') { setTitleErr(true); err = true; }
    else setTitleErr(false);
    if (title?.length > 60) { setTitleLenErr(true); err = true; }
    else setTitleLenErr(false);
    if (content === '') { setContentErr(true); err = true; }
    else setContentErr(false);
    if (password === '') { setPasswordErr(true); err = true; }
    else setPasswordErr(false);
    return err
  }

  useEffect(() => {
    setNameErr(false);
    setTitleErr(false);
    setTitleLenErr(false);
    setContentErr(false);
    setPasswordErr(false);
  }, [name, title, content, password])

  async function handleSubmit(e) {
    e.preventDefault();
    const valid = !validate();
    if (valid) {
      const data = {
        name,
        title,
        content,
        password,
        fileId: file?.id,
      }
      try {
        const res = await createData(data);
        if (res.status === 200) {
          alert('질문이 등록되었습니다.')
          navigate(-1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <article id="qna-create">
      <form className="admin-form" onSubmit={handleSubmit}>
        <section>
          <div className="input-wrap">
            <label htmlFor="name" className="required">이름</label>
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="이름 입력"/>
          </div>
          <div className="error-wrap">
            {nameErr && <p>이름을 입력해주세요.</p>}
          </div>
        </section>
        <section>
          <div className="input-wrap">
            <label htmlFor="title" className="required">제목</label>
            <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="제목 입력"/>
          </div>
          <div className="error-wrap">
            {titleErr && <p>제목을 입력해주세요.</p>}
            {titleLenErr && <p>60자 미만으로 작성해 주세요.</p>}
          </div>
        </section>
        <section>
          <div className="input-wrap">
            <label htmlFor="content" className="required">내용</label>
            <textarea id="content" cols="30" rows="10" value={content} onChange={(e) => setContent(e.target.value)} placeholder="내용 입력"></textarea>
          </div>
          <div className="error-wrap">
            {contentErr && <p>내용을 입력해주세요.</p>}
          </div>
        </section>
        <section>
          <div className="input-wrap">
            <label htmlFor="file">파일첨부</label>
            <UploadFile value={file} setValue={setFile} id="file" />
          </div>
        </section>
        <section>
          <div className="input-wrap">
            <label htmlFor="password" className="required">비밀번호 설정</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="비밀번호 입력"/>
          </div>
          <div className="error-wrap">
            {passwordErr && <p>비밀번호를 입력해주세요.</p>}
          </div>
        </section>
        <section className="button-wrap">
          <button type="submit">확인</button>
        </section>
      </form>
    </article>
  );
}
