import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextEditor from "components/admin/uix/TextEditor";
import UploadFile from "components/admin/uix/UploadFile";
import ToggleButton from "components/admin/uix/ToggleButton";
import { createData } from "api/admin/article";

export default function AdminCreateNotice() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  
  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      boardType: "NOTICE",
      yachtType: null,
      title,
      content,
      url: null,
      thumbnailFileId: null,
      fileId: file.id,
      isOpen,
    };
    try {
      const res = await createData(data);
      if (res.status === 200) {
        alert("등록이 완료되었습니다.");
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  return (
    <article>
      <h1>공지사항 관리</h1>
      <form className="admin-form" onSubmit={handleSubmit}>
        <div className="input-wrap">
          <label className="required" htmlFor="title">
            제목
          </label>
          <input
            className="flex-grow"
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="input-wrap">
          <label className="required whitespace-nowrap">상세내용</label>
          <TextEditor value={content} setValue={setContent} />
        </div>
        <div className="input-wrap">
          <label htmlFor="file">
            파일
          </label>
          <div className="flex flex-col gap-5 p-0 min-w-[29.5rem]">
            <div className="flex flex-col w-full gap-2 p-0">
              <UploadFile value={file} setValue={setFile} id="file" />
            </div>
          </div>
        </div>
        <div className="input-wrap">
          <label htmlFor={"open"}>공개여부</label>
          <ToggleButton value={isOpen} setValue={setIsOpen} id={"open"} />
        </div>
        <div className="flex justify-end gap-5 px-5">
          <button className="w-20 py-2 font-semibold text-white rounded-md bg-theme-light hover:bg-theme-dark">
            등록
          </button>
          <Link
            className="w-20 py-2 font-semibold text-center border rounded-md border-theme-light text-theme-light hover:bg-theme-light hover:text-white"
            to={-1}
          >
            뒤로
          </Link>
        </div>
      </form>
    </article>
  );
}
