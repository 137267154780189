import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function Reservation() {
  const navigate = useNavigate();

  useEffect(() => {
    const onMessage = (event) => {
      if (event.data === "DONE") {
        navigate("/");
        window?.removeEventListener("message", onMessage);
      }
    };
    window?.addEventListener("message", onMessage, false);

    return () => {
      window?.removeEventListener("message", onMessage);
    };
  }, [navigate])
  return (
    <main className="main-pages">
      <header id="reservation-header">
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><NavLink to="/reservation">예약하기</NavLink></li>
        </ul>
      </header>
      <h1 className="title">예약하기</h1>
      <article id="reservation-page">
        <iframe src="https://www.leisureplanner.net/hl/?t=pv&idx=275d7fb2fd45098ad5c3ece2ed4a2824" frameborder="0"></iframe>
      </article>
    </main>
  );
}
