import { upload } from "api/admin/file";

export default function UploadFile({ value, setValue, id }) {
  async function handleChange(e) {
    if (e.target.files[0].size > 10000000) {
      return alert("10mb 이하의 파일을 업로드 해주세요.");
    }
    const uploadForm = new FormData();
    uploadForm.append("file", e.target.files[0]);
    try {
      const res = await upload(uploadForm);
      if (res.status === 200) {
        setValue(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="flex w-full gap-5 p-0">
      <p className="flex items-center w-full h-10 px-4 py-2 text-base border rounded-md border-slate-300">
        {value?.name}
      </p>
      <input
        type="file"
        className="hidden"
        id={id}
        onChange={handleChange}
      />
      <label
        className="p-2 text-white rounded-md cursor-pointer whitespace-nowrap bg-theme-light hover:bg-theme-dark"
        htmlFor={id}
      >
        파일 선택
      </label>
    </div>
  );
}
