import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ImageList from "components/client/uix/ImageList";
import { getList } from "api/pages/event";

export default function EventList() {
  const [eventList, setEventList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectPage, setSelectPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = 10;

  useEffect(() => {
    async function handleGetList() {
      const params = {
        page: searchParams.get("page") ? searchParams.get("page") - 1 : 0,
        size,
        keyword,
      };
      try {
        const res = await getList(params);
        setEventList(res.data.content);
        setSelectPage(res.data.number);
        setPageCount(res.data.totalPages);
      } catch (err) {
        console.log(err);
      }
    }
    handleGetList();
  }, [searchParams]);

  async function handleSubmit(e) {
    e.preventDefault();
    const params = {
      page: 0,
      size,
      keyword,
    };
    try {
      const res = await getList(params);
      setEventList(res.data.content);
      setPageCount(res.data.totalPages);
      setSearchParams({ page: 1 });
      setSelectPage(0);
    } catch (err) {
      console.log(err);
    }
  }

  function handlePageClick(e) {
    setSearchParams({ page: e.selected + 1 });
  }

  return (
    <article id="event-list">
      <div className="search-wrap">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="검색어를 입력하세요."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </form>
      </div>
      <ImageList
        items={eventList}
        handleSubmit={handleSubmit}
        keyword={keyword}
        setKeyword={setKeyword}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        selectPage={selectPage}
      />
    </article>
  );
}
