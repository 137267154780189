import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createData, getData } from "api/admin/support";
import TextEditor from "components/admin/uix/TextEditor";

export default function AdminUpdateSupport() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState();
  const [answer, setAnswer] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function handleGetData() {
      try {
        const res = await getData(id);
        if (res.status === 200) {
          setTitle(res.data.title);
          setContent(res.data.content);
          setFile(res.data.file);
          setAnswer(res.data.answer)
        }
      } catch (err) {
        console.log(err);
      }
    }
    handleGetData();
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      answer,
    };
    try {
      const res = await createData(id, data);
      if (res.status === 200) {
        alert("답변이 등록되었습니다.");
        navigate(-1);
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <article>
      <section>
        <div className="flex flex-col gap-5 py-10">
          <h1>문의 관리</h1>
          <div className="input-wrap">
            <label>제목</label>
            <p>{title}</p>
          </div>
          <div className="input-wrap">
            <label>내용</label>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>

          <div className="input-wrap">
            <label>파일첨부</label>
            {file && (
              <a
                className="hover:underline"
                href={file.url}
                download={file.name}
              >
                {file.name}
              </a>
            )}
          </div>
        </div>
      </section>
      <section>
        <form className="admin-form" onSubmit={handleSubmit}>
          <h1>답변등록</h1>
          <div className="input-wrap">
            <TextEditor value={answer} setValue={setAnswer} />
          </div>
          <div className="flex justify-end gap-5 px-5">
            <button className="w-20 py-2 font-semibold text-white rounded-md bg-theme-light hover:bg-theme-dark">
              등록
            </button>
            <Link
              className="w-20 py-2 font-semibold text-center border rounded-md border-theme-light text-theme-light hover:bg-theme-light hover:text-white"
              to={-1}
            >
              뒤로
            </Link>
          </div>
        </form>
      </section>
    </article>
  );
}
