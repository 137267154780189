import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UploadImage from "components/admin/uix/UploadImage";
import ToggleButton from "components/admin/uix/ToggleButton";
import { createData } from "api/admin/main";

export default function AdminCreateMain() {
  const [title, setTitle] = useState("");
  const [pcImage, setPcImage] = useState("");
  const [mbImage, setMbImage] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      title,
      isOpen,
      desktopFileId: pcImage.id,
      mobileFileId: mbImage.id,
    };
    try {
      const res = await createData(data);
      if (res.status === 200) {
        alert("등록이 완료되었습니다.");
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <article>
      <h1>메인관리</h1>
      <form className="admin-form" onSubmit={handleSubmit}>
        <div className="input-wrap">
          <label className="required" htmlFor="title">
            제목
          </label>
          <input
            className="flex-grow"
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="input-wrap">
          <label className="required">이미지</label>
          <div className="flex flex-col gap-5 p-0">
            <div className="flex flex-col w-full gap-2 p-0">
              <UploadImage
                value={pcImage}
                setValue={setPcImage}
                id="pc-image"
              />
              <p>PC 배너로 노출 될 이미지 업로드 (PC 배너 등록 시 첨부) ※ SIZE : 1920*960 px</p>
            </div>
            <div className="flex flex-col w-full gap-2 p-0">
              <UploadImage
                value={mbImage}
                setValue={setMbImage}
                id="mb-image"
              />
              <p>모바일 배너로 노출 될 이미지 업로드 (PC 배너 등록 시 첨부) ※ SIZE : 720*1120 px</p>
            </div>
          </div>
        </div>
        <div className="input-wrap">
          <label htmlFor={"open"}>공개여부</label>
          <ToggleButton value={isOpen} setValue={setIsOpen} id={"open"} />
        </div>
        <div className="flex justify-end gap-5 px-5">
          <button className="w-20 py-2 font-semibold text-white rounded-md bg-theme-light hover:bg-theme-dark">
            등록
          </button>
          <Link
            className="w-20 py-2 font-semibold text-center border rounded-md border-theme-light text-theme-light hover:bg-theme-light hover:text-white"
            to={-1}
          >
            뒤로
          </Link>
        </div>
      </form>
    </article>
  );
}
