import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UploadImage from "components/admin/uix/UploadImage";
import ToggleButton from "components/admin/uix/ToggleButton";
import { createData } from "api/admin/article";

export default function AdminCreateClubHouse() {
  const [yachtType, setYachtType] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [option, setOption] = useState([]);
  const navigate = useNavigate();

  function getCategory() {
    setOption([
      { id: "", value: "", title: "구분" },
      { id: 1, value: "COFFEE", title: "커피 및 제과" },
      { id: 2, value: "FACILITY", title: "시설" },
    ]);
  }

  useEffect(() => {
    getCategory();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      boardType: "YACHT",
      yachtType,
      title,
      content: null,
      url: null,
      thumbnailFileId: image.id,
      isOpen,
    };
    try {
      const res = await createData(data);
      if (res.status === 200) {
        alert("등록이 완료되었습니다.");
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <article>
      <h1>요트클럽하우스 관리</h1>
      <form className="admin-form" onSubmit={handleSubmit}>
        <div className="input-wrap">
          <label className="required" htmlFor="type">
            구분
          </label>
          <select
            className="h-10 p-2 border rounded-lg border-slate-300"
            id="type"
            value={yachtType}
            onChange={(e) => setYachtType(e.target.value)}
          >
            {option?.map((item) => (
              <option value={item.value} key={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
        <div className="input-wrap">
          <label className="required" htmlFor="title">
            제목
          </label>
          <input
            className="flex-grow"
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="input-wrap">
          <label className="required" htmlFor="image">
            이미지
          </label>
          <div className="flex flex-col gap-5 p-0 min-w-[29.5rem]">
            <div className="flex flex-col w-full gap-2 p-0">
              <UploadImage value={image} setValue={setImage} id="image" />
            </div>
          </div>
        </div>
        <div className="input-wrap">
          <label htmlFor={"open"}>공개여부</label>
          <ToggleButton value={isOpen} setValue={setIsOpen} id={"open"} />
        </div>
        <div className="flex justify-end gap-5 px-5">
          <button className="w-20 py-2 font-semibold text-white rounded-md bg-theme-light hover:bg-theme-dark">
            등록
          </button>
          <Link
            className="w-20 py-2 font-semibold text-center border rounded-md border-theme-light text-theme-light hover:bg-theme-light hover:text-white"
            to={-1}
          >
            뒤로
          </Link>
        </div>
      </form>
    </article>
  );
}
