import { Link } from "react-router-dom";
import SearchForm from "components/client/uix/SearchForm";
import Pagination from "components/common/uix/Pagination";
import { getDateYMD } from "utils";

export default function ImageList({
  items,
  handleSubmit,
  keyword,
  setKeyword,
  handlePageClick,
  pageCount,
  selectPage,
}) {
  return (
    <>
      <SearchForm
        handleSubmit={handleSubmit}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      {items?.length ? (
        <div className="image-list-wrap">
          <div className="image-container">
            {items.map((item) => (
              <div key={item.id} className="image-card">
                <Link to={`${item.id}`}>
                  <img
                    src={item.thumbnailFile.url}
                    alt={item.thumbnailFile.name}
                  />
                </Link>
                <h4>
                  <Link to={`${item.id}`}>{item.title}</Link>
                </h4>
                <p>{getDateYMD(item.createdAt)}</p>
              </div>
            ))}
          </div>
          <div className="pagination-wrap">
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              selectPage={selectPage}
            />
          </div>
        </div>
      ) : (
        <div className="not-found">등록된 게시물이 없습니다.</div>
      )}
    </>
  );
}
