import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "styles/common/base/_reset.scss";
import "styles/common/base/_font.scss";
import ScrollToTop from "components/common/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <RecoilRoot>
          <ScrollToTop />
          <App />
        </RecoilRoot>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);
