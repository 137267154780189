import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "components/common/uix/Pagination";
import { changeData, deleteData, getList } from "api/admin/support";

export default function AdminSupport() {
  const [data, setData] = useState([]);
  const [selectPage, setSelectPage] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = 10;

  async function handleGetList() {
    const params = {
      page: searchParams.get("page") - 1,
      size: size,
    };
    try {
      const res = await getList(params);
      if (res.status === 200) {
        setData(res.data.content);
        setPageCount(res.data.totalPages);
        setTotalCnt(res.data.totalElements);
        setSelectPage(searchParams.get("page") - 1);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleGetList();
  }, [searchParams]);
  
  function handlechangeStatus(id) {
    return async (e) => {
      e.preventDefault();
      try {
        const res = await changeData(id);
        if (res.status === 200) {
          alert("상태가 변경되었습니다.");
          handleGetList();
        }
      } catch (err) {
        console.log(err);
      }
    };
  }
  
  function handledeleteData(id) {
    return async (e) => {
      e.preventDefault();
      if (window.confirm("삭제하시겠습니까?")) {
        try {
          const res = await deleteData(id);
          if (res.status === 200) {
            alert("삭제되었습니다.");
            handleGetList();
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
  }
  
  function getStatus(isAnswered) {
    return isAnswered ? "답변완료" : "접수";
  }
  
  function handlePageClick(e) {
    setSearchParams({ page: e.selected + 1 });
  }

  return (
    <article>
      <section>
        <h1>문의 관리</h1>
      </section>
      <section className="pt-16 pb-14">
        <table className="w-full text-center">
          <thead>
            <tr className="text-white bg-theme-light">
              <th className="px-3 py-4">No.</th>
              <th className="px-3 py-4">제목</th>
              <th className="px-3 py-4">작성자</th>
              <th className="px-3 py-4">작성일</th>
              <th className="px-3 py-4">답변상태</th>
              <th className="px-3 py-4">정보수정</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? data.map((item, index) => (
              <tr key={item.id}>
                <td>{totalCnt - size * selectPage - index}</td>
                <td>
                  <Link className="hover:underline" to={`${item.id}`}>
                    {item.title}
                  </Link>
                </td>
                <td>{item.name}</td>
                <td>{item.createdAt.slice(0, 10)}</td>
                <td className={item.isAnswered ? "font-semibold" : ""}>
                  {getStatus(item.isAnswered)}
                </td>
                <td className="flex justify-center gap-2 p-3">
                  <button
                    className={
                      item.isOpen
                        ? "text-red-500 border border-red-500 hover:text-white hover:bg-red-500"
                        : "border border-theme-light text-theme-light hover:text-white hover:bg-theme-light"
                    }
                    onClick={handlechangeStatus(item.id)}
                  >
                    {item.isOpen ? "비공개" : "공개"}
                  </button>
                  <button
                    className="text-white bg-red-500 hover:bg-red-400"
                    onClick={handledeleteData(item.id)}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            )) : (
              <tr className="not-found"><td colSpan={6}>등록된 게시물이 없습니다.</td></tr>
            )}
          </tbody>
        </table>
        {pageCount ? (
          <div className="flex justify-center mt-10">
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              selectPage={selectPage}
            />
          </div>
        ) : (
          <></>
        )}
      </section>
    </article>
  );
}
