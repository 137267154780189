import { upload } from "api/admin/file";

export default function UploadFile({ value, setValue, id }) {
  async function handleChange(e) {
    if (e.target.files[0].size > 10000000) {
      return alert("10mb 이하의 파일을 업로드 해주세요.");
    }
    const uploadForm = new FormData();
    uploadForm.append("file", e.target.files[0]);
    try {
      const res = await upload(uploadForm);
      if (res.status === 200) {
        setValue(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="upload-file-wrap">
      <p>{value?.name}</p>
      <input type="file" id={id} onChange={handleChange} />
      <label htmlFor={id}>파일첨부</label>
    </div>
  );
}
