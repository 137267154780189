import React from "react";
import { Link } from "react-router-dom";

export default function Product() {
  return (
    <article id="product-page">
      <section className="course-section">
        <h2>코스 안내</h2>
        <table>
          <tbody>
            <tr className="desktop">
              <th>A코스</th>
              <td>오도요트 계류장 - 초란도 - 당사도 - 천사대교</td>
            </tr>
            <tr className="tablet">
              <th>A코스</th>
              <td>오도요트 계류장 - 초란도 - 당사도 - 천사대교</td>
            </tr>
            <tr className="mobile">
              <th>A코스</th>
              <td>
                <span>오도요트 계류장</span>
                <span>초란도</span>
                <span>당사도</span>
                <span>천사대교</span>
              </td>
            </tr>
            <tr className="desktop">
              <th>B코스</th>
              <td>오도요트 계류장 - 암치도 - 백로서식지 - 천사대교</td>
            </tr>
            <tr className="tablet">
              <th>B코스</th>
              <td>오도요트 계류장 - 암치도 - 백로서식지 - 천사대교</td>
            </tr>
            <tr className="mobile">
              <th>B코스</th>
              <td>
                <span>오도요트 계류장</span>
                <span>암치도</span>
                <span>백로서식지</span>
              </td>
              <td>
                <span>천사대교</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p>※ 상기 코스는 기상에 따라 달라 질 수 있습니다.</p>
      </section>
      <section className="fee-section">
        <h2>요금 안내</h2>
        <div className="table-wrap">
          <table>
            <thead>
              <tr>
                <th>구분</th>
                <th className="time-1">시간</th>
                <th>대인/소인</th>
                <th>개인</th>
                <th>단체(10명기준)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={2}>천사 투어</th>
                <th rowSpan={2}>일반 투어 (60분)</th>
                <td>대인</td>
                <td>40,000원</td>
                <td>32,000원</td>
              </tr>
              <tr>
                <td>소인</td>
                <td>30,000원</td>
                <td>24,000원</td>
              </tr>
              <tr>
                <th rowSpan={2}>선셋 투어</th>
                <th rowSpan={2}>일몰&야경 투어 (70분)</th>
                <td>대인</td>
                <td>50,000원</td>
                <td>42,000원</td>
              </tr>
              <tr>
                <td>소인</td>
                <td>40,000원</td>
                <td>34,000원</td>
              </tr>
              <tr>
                <td className="tip" colSpan={5}>
                  <span>* 대인 : 14세 이상</span>
                  <span>* 소인 : 36개월 이상 ~ 12세 미만</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-wrap">
          <table>
            <thead>
              <tr>
                <th>구분</th>
                <th className="time-2">시간</th>
                <th>시즌</th>
                <th>대인/소인</th>
                <th>개인</th>
                <th className="extra">용선 추가 요금</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={3}>용선 투어</th>
                <th rowSpan={3}>전세요트<br />60분/120분 (10명 기준)</th>
                <th>주 중</th>
                <td>600,000원</td>
                <td>1,000,000원</td>
                <td>1인/30,000/50,000</td>
              </tr>
              <tr>
                <td>주 말</td>
                <td>700,000원</td>
                <td>1,200,000원</td>
                <td>1인/32,000/52,000</td>
              </tr>
              <tr>
                <td>성수기</td>
                <td>800,000원</td>
                <td>1,500,000원</td>
                <td>1인/35,000/55,000</td>
              </tr>
              <tr>
                <td colSpan={6} className="tip">
                  <span>* 주중(일 ~ 목요일)</span>
                  <span>* 주말(금 ~ 토요일)</span>
                  <span>* 성수기(7 ~ 8월)</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="ps">
          * 매주 월요일 정기 휴무 (단, 공휴일 및 대체휴무가 월요일 경우 다음날 화요일 휴무)<br />
          * 18:30 (일몰&야경 투어) 일조량에 따라 변동 될 수 있습니다. (예약필수)
        </p>
      </section>
      <section className="schedule-section">
        <h2>탑승/운항시간 안내</h2>
        <table>
          <tbody>
            <tr>
              <th>탑승시간</th>
              <td>
                <span>10:00</span>
                <span>11:30</span>
                <span>14:00</span>
                <span>15:30</span>
                <span className="desktop">17:00</span>
                <span className="desktop">18:30 ~ 17:30(일몰)</span>
                <span className="tablet">17:00</span>
                <span className="tablet">18:30 ~ 17:30(일몰)</span>
              </td>
              <td className="mobile">
                <span>17:00</span>
                <span>18:30 ~ 17:30(일몰)</span>
              </td>
            </tr>
            <tr>
              <th>운항시간</th>
              <td>
                <span>일반투어 : 60분</span>
                <span>일몰투어 : 70분</span>
              </td>
            </tr>
            <tr>
              <th>탑승인원</th>
              <td>
                <span>최대 44명</span>
                <span>최소 10명</span>
              </td>
            </tr>
            <tr>
              <th>이용문의</th>
              <td>
                <span>1004섬 요트투어</span>
                <span>010-9629-1880</span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="link-section">
        <Link to="/gallery">투어 갤러리 보러가기</Link>
        <Link to="/reservation">예약하기</Link>
      </section>
    </article>
  );
}
