import { upload } from "api/admin/file";

export default function UploadThubmanil({ value, setValue, id }) {
  async function handleChange(e) {
    if (e.target.files[0].size > 10000000) {
      return alert("10mb 이하의 파일을 업로드 해주세요.");
    }
    const uploadForm = new FormData();
    uploadForm.append("file", e.target.files[0]);
    try {
      const res = await upload(uploadForm);
      if (res.status === 200) {
        setValue(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="relative w-40 h-40 border border-slate-300">
      {value && (
        <img className="w-full h-full" src={value.url} alt={value.name} />
      )}
      <input
        type="file"
        accept="image/*"
        className="hidden"
        id={id}
        onChange={handleChange}
      />
      <label
        className="absolute left-0 py-2 text-white translate-x-1/2 rounded-md cursor-pointer bottom-5 tran bg-theme-light hover:bg-theme-dark"
        htmlFor={id}
      >
        파일 선택
      </label>
    </div>
  );
}
