import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { adminNavOpenState } from "store";
import Logo from "assets/images/common/logo-white.svg";
import "styles/admin/aside.css";

export default function Aside() {
  const [showArticleMenu, setShowArticleMenu] = useState(false);
  const adminNavOpen = useRecoilValue(adminNavOpenState);
  
  return (
    <aside className={`z-50 box-border fixed top-0 left-0 h-screen text-white w-admin-aside bg-theme ${!adminNavOpen && "-translate-x-full"}`}>
      <div className="flex items-center justify-center h-admin-header text-theme bg-theme-dark">
        <a className="text-center w-44" href="/">
          <img className="w-full h-full" src={Logo} alt="1004yacht" />
        </a>
      </div>
      <nav>
        <ul className="text-lg">
          <li><NavLink className="flex items-center w-full h-12 px-5 hover:bg-theme-light hover:font-semibold" to="main?page=1">메인</NavLink></li>
          <li>
            <button
              className={`flex items-center justify-between w-full h-12 px-5 hover:bg-theme-light hover:font-semibold ${showArticleMenu && "active"}`}
              onClick={() => setShowArticleMenu(!showArticleMenu)}
            >
              게시판 관리
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`w-6 h-6 ${
                  showArticleMenu && "rotate-180"
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
            <ul
              className={`overflow-hidden ${
                showArticleMenu ? "h-60" : "h-0"
              }`}
            >
              <li><NavLink className="flex items-center w-full h-12 pl-10 hover:bg-theme-light hover:font-semibold" to="article/clubhouse?page=1">요트클럽하우스</NavLink></li>
              <li><NavLink className="flex items-center w-full h-12 pl-10 hover:bg-theme-light hover:font-semibold" to="article/press?page=1">보도자료</NavLink></li>
              <li><NavLink className="flex items-center w-full h-12 pl-10 hover:bg-theme-light hover:font-semibold" to="article/notice?page=1">공지사항</NavLink></li>
              <li><NavLink className="flex items-center w-full h-12 pl-10 hover:bg-theme-light hover:font-semibold" to="article/gallery?page=1">갤러리</NavLink></li>
              <li><NavLink className="flex items-center w-full h-12 pl-10 hover:bg-theme-light hover:font-semibold" to="article/event?page=1">이벤트</NavLink></li>
            </ul>
          </li>
          <li><NavLink className="flex items-center w-full h-12 px-5 hover:bg-theme-light hover:font-semibold" to="support?page=1">문의 관리</NavLink></li>
        </ul>
      </nav>
    </aside>
  );
}
