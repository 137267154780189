import React, { useState } from "react";
import ChevronLeft from "assets/images/common/chevron-left.svg";
import ChevronRight from "assets/images/common/chevron-right.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";

export default function DesktopSlide() {
  const [swipe, setSwipe] = useState();
  const [reachingFirst, setReachingFirst] = useState(true);
  const [reachingEnd, setReachingEnd] = useState(false);
  
  return (
    <div className="carousel-wrap desktop">
      <button className="carousel-prev" onClick={() => swipe?.slidePrev()} disabled={reachingFirst}>
        <img src={ChevronLeft} alt="prev" />
      </button>
      <Swiper
        slidesPerView={3}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
        onBeforeInit={(swiper) => setSwipe(swiper)}
        onSlideChange={e => {
          e.isEnd ? setReachingEnd(true) : setReachingEnd(false);
          e.isBeginning ? setReachingFirst(true) : setReachingFirst(false);
        }}
      >
        <SwiperSlide><img src={require("assets/images/pages/intro/slide_1.webp")} alt="slide 1" /></SwiperSlide>
        <SwiperSlide><img src={require("assets/images/pages/intro/slide_2.webp")} alt="slide 2" /></SwiperSlide>
        <SwiperSlide><img src={require("assets/images/pages/intro/slide_3.webp")} alt="slide 3" /></SwiperSlide>
        <SwiperSlide><img src={require("assets/images/pages/intro/slide_4.webp")} alt="slide 4" /></SwiperSlide>
        <SwiperSlide><img src={require("assets/images/pages/intro/slide_5.webp")} alt="slide 5" /></SwiperSlide>
        <SwiperSlide><img src={require("assets/images/pages/intro/slide_6.webp")} alt="slide 6" /></SwiperSlide>
      </Swiper>
      <button className="carousel-next" onClick={() => swipe?.slideNext()} disabled={reachingEnd}>
        <img src={ChevronRight} alt="next" />
      </button>
    </div>
  );
}