import { apiInstance } from "api/instace";

export default async function getCoffee() {
  const res = await apiInstance.get("/introduction/yacht", {
    params: {
      page: 0,
      size: 10,
      yachtType: "COFFEE",
    },
  });
  return res;
}
