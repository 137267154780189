import React, { useState } from "react";
import { Cookies } from "react-cookie";
import { login } from "api/admin/auth";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const cookie = new Cookies();
  async function handleSubmit(e) {
    e.preventDefault();
    if (!username || !password) {
      return alert("아이디와 비밀번호를 입력해주세요.");
    }
    const params = {
      username,
      password,
    };
    try {
      const res = await login(params);
      if (res.status === 200) {
        const expires = new Date();
        expires.setHours(expires.getHours() + 1);
        cookie.set("admin", true, { path: "/admin", expires });
        window.location.href = "/admin";
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <article className="flex items-center justify-center w-screen h-screen">
      <form
        className="flex flex-col items-center w-1/4 gap-10"
        onSubmit={handleSubmit}
      >
        <label className="text-3xl font-bold text-center">로그인</label>
        <input
          className="w-96"
          type="text"
          placeholder="아이디를 입력하세요"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="w-96"
          type="password"
          placeholder="비밀번호를 입력하세요."
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="p-4 mx-auto text-lg text-white rounded-lg bg-theme-light"
          type="submit"
        >
          로그인
        </button>
      </form>
    </article>
  );
}
