export default function ToggleButton({ value, setValue, id }) {
  return (
    <input
      className="toggle-button"
      id={id}
      type="checkbox"
      role="switch"
      checked={value}
      onChange={({ target: { checked } }) => setValue(checked)}
    />
  );
}
