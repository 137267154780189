import axios from "axios";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + '/admin',
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});

export { apiInstance, adminInstance };
