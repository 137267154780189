import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "components/common/uix/Pagination";
import { getList } from "api/pages/support/qna";
import { getDateYMD, getShortText } from "utils";

export default function QnaList() {
  const [qnaList, setQnaList] = useState([]);
  const [selectPage, setSelectPage] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = 10;

  useEffect(() => {
    async function handleGetList() {
      const params = {
        page: searchParams.get("page") ? searchParams.get("page") - 1 : 0,
        size,
      };
      try {
        const res = await getList(params);
        if (res.status === 200) {
          setQnaList(res.data.content);
          setSelectPage(res.data.number);
          setPageCount(res.data.totalPages);
          setTotalCnt(res.data.totalElements);
        }
      } catch (err) {
        console.log(err);
      }
    }
    handleGetList();
  }, [searchParams]);

  function handlePageClick(e) {
    setSearchParams({ page: e.selected + 1 });
  }

  return (
    <article id="qna-list">
      <section>
        {qnaList?.length ? (
          <div className="qna-table-wrap">
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th className="desktop">작성일</th>
                  <th className="desktop">답변</th>
                  <th className="tablet">작성일</th>
                  <th className="tablet">답변</th>
                </tr>
              </thead>
              <tbody>
                {qnaList?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{totalCnt - size * selectPage - index}</td>
                    <td>
                      {item.url ? 
                        <>
                          <a className="desktop" href={item.url} target="_blank" rel="noreferrer">{getShortText(item.title, 120)}</a>
                          <a className="tablet" href={item.url} target="_blank" rel="noreferrer">{getShortText(item.title, 70)}</a>
                          <a className="mobile" href={item.url} target="_blank" rel="noreferrer">{getShortText(item.title, 20)}</a>
                        </> : 
                        <>
                          <Link className="desktop" to={`${item.id}`}>{getShortText(item.title, 120)}</Link>
                          <Link className="tablet" to={`${item.id}`}>{getShortText(item.title, 70)}</Link>
                          <Link className="mobile" to={`${item.id}`}>{getShortText(item.title, 20)}</Link>
                        </>
                      }
                    </td>
                    <td>{item.name}</td>
                    <td className="desktop">{getDateYMD(item.createdAt)}</td>
                    <td className="desktop">{item.isAnswered ? "답변완료" : "접수"}</td>
                    <td className="tablet">{getDateYMD(item.createdAt)}</td>
                    <td className="tablet">{item.isAnswered ? "답변완료" : "접수"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="article-pagination-wrap">
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={pageCount}
                selectPage={selectPage}
              />
            </div>
          </div>
        ) : (
          <div className="not-found">등록된 게시물이 없습니다.</div>
        )}
        <div className="button-wrap">
          <Link className="create" to="create">등록</Link>
        </div>
      </section>
    </article>
  );
}
