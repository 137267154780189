import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

export default function Intro() {
  const [title, setTitle] = useState();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/policy/privacy") {
      setTitle("개인정보처리방침");
    } else if (pathname === "/policy/use") {
      setTitle("이용약관");
    } else {
      setTitle("운행약관");
    }
  }, [pathname])
  
  return (
    <main className="main-pages">
      <header id="policy-header">
        <picture>
          <source media="(min-width: 1024px)" srcSet={require("assets/images/header/policy_desktop.webp")}/>
          <source media="(min-width: 768px)" srcSet={require("assets/images/header/policy_tablet.webp")}/>
          <img src={require("assets/images/header/policy_mobile.webp")} alt="policy" />
        </picture>
        <ul>
          <li><Link to="/">홈</Link></li>
          <li><NavLink to={pathname}>{title}</NavLink></li>
        </ul>
      </header>
      <h1 className="title">{title}</h1>
      <article id="policy">
        <Outlet />
      </article>
    </main>
  );
}
