import React from "react";

export default function Operation() {
  return (
    <>
      <section>
        <h4>제1조 (목적)</h4>
        <p>
          본 약관은 1004섬요트관광 주식회사의 마리나 선박대여업(세일요트 3004호)
          이용객 이용조건에 대한 사업자의 책임, 피해 보상을 위한 보험 가입,
          사업자와 이용객 간의 이용에 관한 사항을 규정함을 목적으로 한다.
        </p>
      </section>
      <section>
        <h4>제2조 (용어의 정의)</h4>
        <p>
          본 약관에서 사용하는 용어의 정의는 다음과 같다.
          <br />
          1. "마리나 선박대여업"이라 함은 요트 및 계류장을 갖추고 바다에서
          어렵·관광 기타 유락을 위하여 선박을 대여, 유락하는 사람을 승선시키는
          것을 영업으로 하는 것을 말한다.
          <br />
          2. "계류장"이라 함은 요트를 안전하게 매어 두고 승객이 승·하선 할 수
          있게 한 계류시설과 편의시설을 갖춘 클럽 하우스를 말한다.
          <br />
          3. “사업자”라 함은 1004섬요트관광 주식회사 직원, 선원 및 종사자를
          말한다.
          <br />
          4. “이용객”이라 함은 요트투어를 목적으로 승선권을 구매한 고객을
          말한다.
        </p>
      </section>
      <section>
        <h4>제3조 (이용 원칙)</h4>
        <p>
          사업자는 친철 봉사를 본위로 하며 안전 운항을 위하여 신의와 성실을
          경주한다. 이용객에게 안전한 요트투어 서비스를 제공하기 위하여 맡은 바
          임무를 충실히 수행하여야 한다.
        </p>
      </section>
      <section>
        <h4>제4조 (적용 범위)</h4>
        <p>
          본 약관은 1004섬요트관광 주식회사 요트투어의 사업에 관하여 적용되며,
          관계법규, 행정관청의 지침에 저촉된 사항에 대하여는 적용하지 아니한다.
          또한 본 약관에 규정되지 않은 사항에 대하여는 마리나 선박대여업과 상법
          및 일반관습에 따른다.
        </p>
      </section>
      <section>
        <h4>제5조 (요금)</h4>
        <p>
          ① 승객 요금은 마리나법 제28조의5(등록사업자의 의무)및 동법 시행규칙
          제27조의 규정에 의하여 지방해양수산청장에게 신고한 요금에 의한다.
          <br />② 승객의 요금 적용 구분은 첨부 1 참조.
        </p>
      </section>
      <section>
        <h4>제6조 (예약)</h4>
        <p>
          ① 요트투어 승선을 예약하는 경우 승선요금의 50%를 예약금으로 지불하여야
          한다.
          <br />② 예약의 우선순위는 제1항의 예약금을 지불 한 시점으로 한다.
        </p>
      </section>
      <section>
        <h4>제7조 (요금의 수수 및 승선권)</h4>
        <p>
          승선권은 사업자 영업소(클럽하우스)에서 요금 수수 후 발행한다.
          <br />
          다만, 온라인이나 제휴 여행사의 예약을 통한 이용객일 경우에는
          영업소에서 사업자의 예매권과 신원 확인을 거친 후 발행한다.
        </p>
      </section>
      <section>
        <h4>제8조 (예약금의 환불)</h4>
        <p>
          ① 예약일시에 주의보 등 선박의 출항이 불가능 할 때와 사업자가 안전상의
          이유로 출항을 하지 못할 시에는 전액 환불한다.
          <br />
          ② 이용객의 변심에 의한 예약 취소인 경우에는 다음의 각 호에 의한다.
          <br />
          1. 48시간 이전 : 전액 환불
          <br />
          2. 24시간 이전 48시간 이내 : 총 예약금 중 환불 수수료(총 요금의 20%)를
          제외한 나머 지 금액
          <br />
          3. 24시간 이내 : 총 예약금 중 환불 수수료(총 요금의 50%)를 제외한 금액
          나머지 금액
        </p>
      </section>
      <section>
        <h4>제9조 (요금의 환불)</h4>
        <p>
          사업자는 출항 전 또는 항해 도중 선박의 기관 고장, 선박의 사고 일기
          등으로 선박 운항을 하지 못 하는 경우에는 다음의 각 호 기준에 의하여
          환불 한다.
          <br />
          1. 날씨 등 사업자의 책임이 없는 사유로 인하여 선박이 운항하지 못하는
          경우
          <br />
          *출항 전 : 전액 환불
          <br />
          *출항 후 : 가. 총 시간의 50%가 경과 하지 아니한 경우 - 전액 환불
          <br />
          나. 총 시간의 50%가 경과한 경우 - 50% 환불
          <br />
          2. 선박의 기관 고장, 사고 등 사업자의 책임으로 인하여 선박이 운항하지
          못하는 경우
          <br />
          *출항 전 : 전액 환불
          <br />
          3. 예약 후 이용객 변심 등의 이유로 승선을 거부한 경우
          <br />
          *출항 전 : 총 승선 요금의 50%를 제외한 금액
          <br />
          *출항 후 : 승선 시간에 관계없이 환불 하지 아니한다.
        </p>
      </section>
      <section>
        <h4>제10조 (사업자의 면책)</h4>
        <p>
          사업자는 다음 사유로 인하여 발생한 연착, 위해 및 손해에 대하여 책임을
          지지 아니한다.
          <br />
          1. 천재지변, 해난, 전쟁, 변란, 사변 기타 이에 준하는 재난
          <br />
          2. 법령. 규칙 등의 집행
          <br />
          3. 기타 공권에 의한 제한
          <br />
          4. 승객의 질병 또는 불법행위
        </p>
      </section>
      <section>
        <h4>제11조 (요트투어의 중지 및 항로 변경)</h4>
        <p>
          ① 사업자는 인명, 재산 또는 타 선박의 구조, 피난 또는 명령 기타
          부득이한 사유가 있을 때에는 승객의 안전을 위하여 예고 없이 선박의 출항
          일시, 항해의 순서를 변경하여 타 항에의 기항 또는 규정 이외의 항로를
          항해할 수 있다.
          <br />② 제 1항의 사유로 인하여 발생하는 연착, 위해, 기타 일체의 손해에
          대하여 사업자는 승객에게 책임을 지지 아니한다.
        </p>
      </section>
      <section>
        <h4>제12조 (배상 책임)</h4>
        <p>
          이용객에 대한 배상 책임은 본 이용약관의 규정에 의한 면책 사유에
          해당하는 경우를 제외하고는 마리나 사업자 배상책임보험의 약관이 정하는
          바에 의하여 피해 보상의 책임을 진다.
        </p>
      </section>
      <section>
        <h4>제13조 (사업자의 보험가입)</h4>
        <p>
          마리나 선박대여업자는 마리나법 제28조 8항(보험 가입 등)에 의한 피해
          보상을 위하여 마리나 사업자 배상책임보험에 가입한다.
        </p>
      </section>
      <section>
        <h4>제14조 (이용객의 의무)</h4>
        <p>
          1. 승객은 요트 세일링 중에 있어서 선내 규칙을 준수하여야 하며,
          안전하고 즐거운 요트 투어를 위하여 이용자 간 화합도모 및 안전 요원의
          질서유지에 따라야 한다.
          <br />
          2. 승객은(요트)승선에 앞서 인적 사항을 기재하여 이를 사업자에게
          제출하여야 하며 허위기재 또는 이를 이행치 않음으로써 발생되는 사항에
          대한 사업자의 책임은 면제된 다.
          <br />
          3. 이용객은 승무원이 안전상의 이유로 제지하였을 경우 그 지시에 따라야
          한다.
          <br />
          4. 쾌적하고 청결한 환경 및 안전을 위하여 하이힐, 구두 등 안전사고를
          유발할 수 있는 신발 착용을 금지한다.
          <br />
          5. 요트에서는 화재 등 안전을 위해 금연한다.
          <br />
          6. 사업목적의 촬영은 사전에 회사의 허가를 받아야만 가능하다.
          <br />
          7. 안경, 시계, 휴대폰, 카메라, 악세사리 등의 개인물품 분실 시 회사는
          책임지지 않는다.
        </p>
      </section>
      <section>
        <h4>제15조 (사업자의 승선 제한 및 거절)</h4>
        <p>
          사업자는 다음의 경우에는 승선을 제한하거나 거절할 수 있다.
          <br />
          1. 승객이 법령 또는 선내 규칙을 위반하여 공공질서와 풍기문란 또는
          위생상 해로움이 있다고 인정될 때.
          <br />
          2. 승객이 정신병, 전염병 증세가 있을 때.
          <br />
          3. 간호인을 필요로 하는 노약자나 병약자가 간호인을 동반하지 않을 때.
          <br />
          4. 총포, 화약류 등 법령 또는 명령에 의하여 이동이 금지된 물품 소지 때.
          <br />
          5. 승객이 주정, 기타의 사유로 인하여 타인에게 위해 또는 피해를 끼칠
          우려가 있다고 인 정 될 때
        </p>
      </section>
      <section>
        <h4>제16조 (운항 제한)</h4>
        <p>
          사업자는 민원이나 기타 운항 상의 지장이 있을 때에는 다음의 조치를 취할
          수 있다.
          <br />
          1. 승선권의 발매 제한 및 정지
          <br />
          2. 승선 구간, 승선 방법, 선편의 제한
        </p>
      </section>
      <section>
        <h4>제17조 (효력)</h4>
        <p>
          ① 본 이용약관은 1004섬요트관광 주식회사 요트투어의 마리나 선박대여업
          관리위탁 개시 일부터 적용한다.
          <br />
          ② 본 이용약관은 일반 이용객이 쉽게 볼 수 있도록 클럽하우스 내에
          제시하여야 한다.
          <br />③ 이용객은 승선권을 구입함으로써 본 약관에 동의한 것으로 본다.
        </p>
      </section>
      <section>
        <h4>&lt;부칙&gt;</h4>
        <p>- 본 이용약관은 2020년 3월 10일부터 시행한다.</p>
      </section>
    </>
  );
}
